import { render, staticRenderFns } from "../../assets/templates/StartPlataformas/CompanyManager.html?vue&type=template&id=de10d440&scoped=true&"
import script from "../../assets/scripts/StartPlataformas/CompanyManager.js?vue&type=script&lang=js&"
export * from "../../assets/scripts/StartPlataformas/CompanyManager.js?vue&type=script&lang=js&"
import style0 from "../../assets/scss/companyManager.scss?vue&type=style&index=0&id=de10d440&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de10d440",
  null
  
)

export default component.exports