import {
  valueOrDefault,
  parseNumber
} from "../../../functions/functions";

export default {
  name: "FDVPage",
  props: {
    stateFDV: Boolean,
  },

  data() {
    return {
      remoteId: 0,
      minimumPurchaseAmount: 0,
      showUnavailableProducts: false,
      hasDeliveryGatewayTax: false,
      deliveryNotes: "",
      pickUpOnSiteNotes: "",
      isThirdPartyDeliveryGatewayPreferred: false,
      tax: 0,
      usersFranchise: 0,
      amountPerFranchise: 0,
      monthlyAllowedRefounds: 0,
      minimumBillingAmount: 0,
      isRequestConfirmationOrder: false,
      notesRequiredTextAlert: "",
      showInterests: false,
      state: null,
    };
  },

  beforeMount() {
    this.readCompany();
    this.state = this.verifyState();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.state = this.verifyState();

    if (this.state) {
      this.$emit("continue-commerce", true);
      localStorage.removeItem("stateCommerce");
      localStorage.setItem("stateCommerce", "true");
    } else {
      this.$emit("continue-commerce", true);
      localStorage.removeItem("stateCommerce");
      localStorage.setItem("stateCommerce", "false");
    }
  },

  computed: {
    stateRemoteId() {
      return this.remoteId ? true : false
    },
    stateMinimumPurchaseAmount() {
      return this.minimumPurchaseAmount ? true : false
    },
    stateTax() {
      return this.tax ? true : false
    },
    stateUsersFranchise() {
      return this.usersFranchise ? true : false
    },
    stateAmountPerFranchise() {
      return this.amountPerFranchise ? true : false
    },
    stateMonthlyAllowedRefounds() {
      return this.monthlyAllowedRefounds ? true : false
    },
    stateMinimumBillingAmount() {
      return this.minimumBillingAmount ? true : false
    }
  },

  methods: {
    verifyState() {
      if (
        this.remoteId &&
        this.minimumPurchaseAmount &&
        this.tax &&
        this.usersFranchise &&
        this.amountPerFranchise &&
        this.monthlyAllowedRefounds &&
        this.minimumBillingAmount
      ) {
        this.$emit("continue-commerce", true);
        localStorage.removeItem("stateCommerce");
        localStorage.setItem("stateCommerce", "true");
        return true
      }
      this.$emit("continue-commerce", false);
      localStorage.removeItem("stateCommerce");
      localStorage.setItem("stateCommerce", "false");
      return false

    },

    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.amountPerFranchise = this.$route.params.amountPerFranchise;
      this.deliveryNotes = this.$route.params.deliveryNotes;
      this.hasDeliveryGatewayTax = this.$route.params.hasDeliveryGatewayTax;
      this.isRequestConfirmationOrder = this.$route.params.isRequestConfirmationOrder;
      this.isThirdPartyDeliveryGatewayPreferred = this.$route.params.isThirdPartyDeliveryGatewayPreferred;
      this.minimumBillingAmount = this.$route.params.minimumBillingAmount;
      this.minimumPurchaseAmount = this.$route.params.minimumPurchaseAmount;
      this.monthlyAllowedRefounds = this.$route.params.monthlyAllowedRefounds;
      this.notesRequiredTextAlert = this.$route.params.notesRequiredTextAlert;
      this.pickUpOnSiteNotes = this.$route.params.pickUpOnSiteNotes;
      this.remoteId = this.$route.params.remoteId;
      this.showInterests = this.$route.params.showInterests;
      this.showUnavailableProducts = this.$route.params.showUnavailableProducts;
      this.tax = this.$route.params.tax;
      this.usersFranchise = this.$route.params.usersFranchise;
    },

    fillLocalStorage() {
      localStorage.setItem("remoteIdFDVPage", parseNumber(this.remoteId, 0));
      localStorage.setItem(
        "minimumPurchaseAmountFDVPage",
        parseFloat(this.minimumPurchaseAmount)
      );
      localStorage.setItem(
        "showUnavailableProductsFDVPage",
        this.showUnavailableProducts
      );
      localStorage.setItem(
        "hasDeliveryGatewayTaxFDVPage",
        this.hasDeliveryGatewayTax
      );
      localStorage.setItem("deliveryNotesFDVPage", valueOrDefault(this.deliveryNotes, ""));
      localStorage.setItem("pickUpOnSiteNotesFDVPage", valueOrDefault(this.pickUpOnSiteNotes, ""));
      localStorage.setItem(
        "isThirdPartyDeliveryGatewayPreferredFDVPage",
        this.isThirdPartyDeliveryGatewayPreferred
      );
      localStorage.setItem("taxFDVPage", parseFloat(this.tax));
      localStorage.setItem("usersFranchiseFDVPage", parseNumber(this.usersFranchise, 0));
      localStorage.setItem(
        "amountPerFranchiseFDVPage",
        parseFloat(this.amountPerFranchise)
      );
      localStorage.setItem(
        "monthlyAllowedRefoundsFDVPage",
        parseNumber(this.monthlyAllowedRefounds, 0)
      );
      localStorage.setItem(
        "minimumBillingAmountFDVPage",
        parseFloat(this.minimumBillingAmount)
      );
      localStorage.setItem(
        "isRequestConfirmationOrderFDVPage",
        this.isRequestConfirmationOrder
      );
      localStorage.setItem(
        "notesRequiredTextAlertFDVPage",
        valueOrDefault(this.notesRequiredTextAlert, "")
      );
      localStorage.setItem("showInterestsFDVPage", this.showInterests);
    },

    clearLocalStorage() {
      localStorage.removeItem("remoteIdFDVPage");
      localStorage.removeItem("minimumPurchaseAmountFDVPage");
      localStorage.removeItem("showUnavailableProductsFDVPage");
      localStorage.removeItem("hasDeliveryGatewayTaxFDVPage");
      localStorage.removeItem("deliveryNotesFDVPage");
      localStorage.removeItem("pickUpOnSiteNotesFDVPage");
      localStorage.removeItem("isThirdPartyDeliveryGatewayPreferredFDVPage");
      localStorage.removeItem("taxFDVPage");
      localStorage.removeItem("usersFranchiseFDVPage");
      localStorage.removeItem("amountPerFranchiseFDVPage");
      localStorage.removeItem("monthlyAllowedRefoundsFDVPage");
      localStorage.removeItem("minimumBillingAmountFDVPage");
      localStorage.removeItem("isRequestConfirmationOrderFDVPage");
      localStorage.removeItem("notesRequiredTextAlertFDVPage");
      localStorage.removeItem("showInterestsFDVPage");
    },
  },
};