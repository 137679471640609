import {
  valueOrDefault,
  parseNumber,
} from "../../../functions/functions";

export default {
  name: "CRMPage",
  props: {
    stateCRM: Boolean,
  },

  data() {
    return {
      monthlyPushsLocal: 0,
      allowedDevicesLocal: 0,
      firebaseProjectCodeLocal: "",
      firebaseProjectApiLocal: "",
      showOriginalPricePromotionLocal: false,
      registrationDiscountTypeLocal: 0,
      registrationDiscountAmountLocal: 0,
      menuTypeLocal: 2,
      useFDVLocal: false,
      isGasStationLocal: false,

      selectedUsesCRM: 1,
      optionsUsesCRM: [{
          value: 1,
          text: "Sem programa de fidelização",
        },
        {
          value: 2,
          text: "Com programa de fidelização - Pontos",
        },
        {
          value: 3,
          text: "Com programa de fidelização - Cashback",
        },
      ],

      selectedOptionMenu: 2,
      optionsMenu: [{
          value: 1,
          text: "Menu Lateral",
        },
        {
          value: 2,
          text: "Menu Inferior",
        },
      ],

      selectedOptionDiscount: 2,
      optionsDiscount: [{
          value: 1,
          text: "Porcentagem",
        },
        {
          value: 2,
          text: "Valor",
        },
      ],
      state: null,
    };
  },

  beforeMount() {
    this.readCompany();
    this.state = this.verifyState();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.state = this.verifyState();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.state = this.verifyState();

    if (this.state) {
      this.$emit("continue-fdv", true);
      localStorage.removeItem("stateFDV");
      localStorage.setItem("stateFDV", "true");
    } else {
      this.$emit("continue-fdv", true);
      localStorage.removeItem("stateFDV");
      localStorage.setItem("stateFDV", "false");
    }
  },

  computed: {
    stateUsesCRM() {
      return this.selectedUsesCRM ? true : false
    },
    stateMonthlyPushs() {
      return this.monthlyPushsLocal ? true : false
    },
    stateAllowedDevices() {
      return this.allowedDevicesLocal ? true : false
    },
    stateRegistrationDiscountType() {
      return this.selectedOptionDiscount ? true : false
    },
    stateRegistrationDiscountAmount() {
      return this.registrationDiscountAmountLocal ? true : false
    },
    stateMenuType() {
      return this.selectedOptionMenu ? true : false
    }
  },

  methods: {
    verifyState() {
      if (
        this.selectedUsesCRM &&
        this.monthlyPushsLocal &&
        this.allowedDevicesLocal &&
        this.selectedOptionDiscount &&
        this.registrationDiscountAmountLocal &&
        this.selectedOptionMenu
      ) {
        this.$emit("continue-fdv", true);
        localStorage.removeItem("stateFDV");
        localStorage.setItem("stateFDV", "true");
        return true
      }

      this.$emit("continue-fdv", false);
      localStorage.removeItem("stateFDV");
      localStorage.setItem("stateFDV", "false");
      return false
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.allowedDevicesLocal = this.$route.params.allowedDevices;
      this.firebaseProjectCodeLocal = this.$route.params.firebaseProjectId;
      this.firebaseProjectApiLocal = this.$route.params.firebaseProjectKey;
      this.isGasStationLocal = this.$route.params.isGasStation;
      this.selectedOptionMenu = this.$route.params.menuType;
      this.selectedUsesCRM = this.$route.params.usesCRM;
      this.monthlyPushsLocal = this.$route.params.monthlyPushs;
      this.selectedOptionDiscount = this.$route.params.registerDiscountType;
      this.registrationDiscountAmountLocal = this.$route.params.registerDiscountValue;
      this.useFDVLocal = this.$route.params.usesFDV;
      this.showOriginalPricePromotionLocal = this.$route.params.showOriginalPromotionPrice;
    },

    fillLocalStorage() {
      localStorage.setItem("usesCRMPage", parseNumber(this.selectedUsesCRM, 1));
      localStorage.setItem("useFDVCRMPage", this.useFDVLocal);
      localStorage.setItem(
        "showOriginalPricePromotionCRMPage",
        this.showOriginalPricePromotionLocal
      );
      localStorage.setItem("isGasStationCRMPage", this.isGasStationLocal);
      localStorage.setItem("menuTypeCRMPage", parseNumber(this.selectedOptionMenu, 2));
      localStorage.setItem(
        "registrationDiscountTypeCRMPage",
        parseNumber(this.selectedOptionDiscount, 2)
      );
      localStorage.setItem("monthlyPushsCRMPage", parseNumber(this.monthlyPushsLocal, 0));
      localStorage.setItem("allowedDevicesCRMPage", parseNumber(this.allowedDevicesLocal, 0));
      localStorage.setItem(
        "registrationDiscountAmountCRMPage",
        parseFloat(this.registrationDiscountAmountLocal)
      );
      localStorage.setItem(
        "firebaseProjectCodeCRMPage",
        valueOrDefault(this.firebaseProjectCodeLocal, "")
      );
      localStorage.setItem(
        "firebaseProjectApiCRMPage",
        valueOrDefault(this.firebaseProjectApiLocal, "")
      );
    },

    clearLocalStorage() {
      localStorage.removeItem("usesCRMPage");
      localStorage.removeItem("useFDVCRMPage");
      localStorage.removeItem("showOriginalPricePromotionCRMPage");
      localStorage.removeItem("isGasStationCRMPage");
      localStorage.removeItem("menuTypeCRMPage");
      localStorage.removeItem("registrationDiscountTypeCRMPage");
      localStorage.removeItem("monthlyPushsCRMPage");
      localStorage.removeItem("allowedDevicesCRMPage");
      localStorage.removeItem("registrationDiscountAmountCRMPage");
      localStorage.removeItem("firebaseProjectCodeCRMPage");
      localStorage.removeItem("firebaseProjectApiCRMPage");
    },
  },
};