import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/assets/scss/style.scss";
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSimpleAlert from "vue-simple-alert";
import '@fortawesome/fontawesome-free/css/all.css'
import VueToastify from "vue-toastify";

Vue.use(VueSimpleAlert);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToastify);
Vue.use(Loading);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
