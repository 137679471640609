import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "UsersManager",
  components: {
    Loading
  },
  data() {
    return {

      isLoading: false,
      name: "",
      login: "",
      email: "",
      password: "",
      passwordConfirmed: "",
      state: null
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.readCompany();
    this.state = this.verifyState();
    this.isLoading = false;
  },

  async updated() {
    this.state = await this.verifyState()
  },

  computed: {
    nameState() {
      return this.name ? true : false
    },
    loginState() {
      return this.login ? true : false
    },
    passwordState() {
      return this.password ? true : false
    },
    passwordConfirmedState() {
      return this.passwordConfirmed ? true : false
    },
    emailState() {
      return this.email ? true : false
    }
  },

  methods: {
    verifyState() {
      if (
        this.name != "" &&
        this.login != "" &&
        this.password != "" &&
        this.passwordConfirmed != "" &&
        this.email != ""
      )
        return true
      else
        return false
    },

    redirect(ref) {
      this.$router.push(ref);
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    verifyPassword(newPassword, confirmedPassword) {
      if (newPassword != confirmedPassword)
        return false
      else
        return true
    },

    showPassword(id) {
      let password = document.getElementById(id);

      if (password.type == "password") {
        password.type = "text";
      } else {
        password.type = "password";
      }

      this.focus("password");
    },

    async buildUser(name, login, email, password) {
      return {
        name,
        login,
        email,
        password
      }
    },

    readCompany() {
      this.name = this.$route.params.name;
      this.login = this.$route.params.login;
      this.email = this.$route.params.email;
    },

    async CreateOrUpdate() {
      this.isLoading = true;

      if (this.password && this.confirmedPassword) {
        const passwordVerified = this.verifyPassword();

        if (!passwordVerified) {
          this.isLoading = false;
          await this.$alert("As senhas digitadas não conferem!", "Error", "error");
        }
      }

      const url = `${config.default.urlBasePandora}/admin/user`;
      const builtUser = await this.buildUser(this.name, this.login, this.email, this.password);

      try {
        const response = await axios({
          url,
          method: "post",
          data: builtUser,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          await this.$alert("Usuário cadastrado!", "Sucesso", "success");
          this.redirect("users-pca");
        }
      } catch (error) {
        if (error.response.status == 403) {
          this.isLoading = false;
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else {
          this.isLoading = false;
          await this.$alert("Falha ao cadastrar o usuário!", "Error", "error");
        }
        console.log({
          error
        })
      }

    },
  },
};