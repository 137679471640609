import axios from "axios";
import * as config from "@/config.json";

import {
  parseNumber,
  valueOrDefault
} from "../../../functions/functions";

export default {
  name: "VinculosPage",

  data() {
    return {
      selectedAccessType: 1,
      optionsAccessType: [],
      selectedMicroService: 1,
      optionsMicroService: [],
      ip: "",
      port: "",
      apiKey: "",
      apiSecret: "",
      tokenDNS: "",
      token: "",
      dns: [],
      fields: [{
          key: "id",
          label: "Id"
        },
        {
          key: "microServiceDescription",
          label: "Micro Serviço"
        },
        {
          key: "ip",
          label: "Ip"
        },
        {
          key: "port",
          label: "Porta"
        },
        {
          key: "apiKey",
          label: "API Key"
        },
        {
          key: "apiSecret",
          label: "API Secret"
        },
        {
          key: "token",
          label: "Token"
        },
      ],
      currentPage: 1,
      perPage: 10,
      idVinculoDNS: "",
      editVinculoDNS: false,
      insertingVinculoDNS: false,
    };
  },

  methods: {
    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.dns = this.$route.params.dns;
    },

    editDns(dns) {
      this.idVinculoDNS = dns.id;
      this.ip = dns.ip;
      this.port = dns.port;
      this.apiKey = dns.apiKey;
      this.apiSecret = dns.apiSecret;
      this.tokenDNS = dns.token;

      this.selectedMicroService = dns.microServiceId;

      this.editVinculoDNS = true;
    },

    async buildDNSObject(
      cnpj,
      microServiceId,
      ip,
      port,
      apiKey,
      apiSecret,
      token
    ) {

      if (cnpj && microServiceId && ip && port) {

        const DNSs = {
          companyCnpj: valueOrDefault(cnpj, ""),
          microServiceId: parseNumber(microServiceId),
          ip: valueOrDefault(ip, ""),
          port: valueOrDefault(port, ""),
          apiKey: valueOrDefault(apiKey, ""),
          apiSecret: valueOrDefault(apiSecret, ""),
          token: valueOrDefault(token, ""),
        };
        return DNSs;
      }
      return null;
    },

    async gravarDNS() {
      const url = `${config.default.urlBasePandora}/companies/company-dns`;

      try {
        const response = await axios({
          url,
          method: "post",
          data: await this.buildDNSObject(localStorage.getItem("cnpjPrincipalPage"), this.selectedMicroService, this.ip, this.port, this.apiKey, this.apiSecret, this.tokenDNS),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.status === 200) {
          this.editVinculoDNS = false;
          await this.$alert("DNS cadastrado!", "Sucesso", "success");
          await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
          this.clearFields();
        }
      } catch (error) {
        console.log(error);
        await this.$alert("Erro ao cadastrar o DNS!", "Erro", "error");
      }
    },

    clearFields() {
      this.idVinculoDNS = "";
      this.ip = "";
      this.port = "";
      this.apiKey = "";
      this.apiSecret = "";
      this.tokenDNS = "";

      this.editVinculoDNS = false;
    },

    async getMicroService() {
      const url = `${config.default.urlBasePandora}/admin/micro-service-type`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.optionsMicroService = await Promise.all(response.data.microServiceTypes.map(item => {
            return {
              value: item.id,
              text: item.description,
            };
          }));
        }
      } catch (error) {
        console.log({
          error
        });
      }
    },

    fillLocalStorage() {
      localStorage.setItem("microServiceIdDNSPage", parseNumber(this.selectedMicroService, 1));
      localStorage.setItem("ipDNSPage", valueOrDefault(this.ip,""));
      localStorage.setItem("portDNSPage", valueOrDefault(this.port,""));
      localStorage.setItem("apiKeyDNSPage", valueOrDefault(this.apiKey,""));
      localStorage.setItem("apiSecretDNSPage", valueOrDefault(this.apiSecret,""));
      localStorage.setItem("tokenDNSPage", valueOrDefault(this.token,""));
    },

    clearLocalStorage() {
      localStorage.removeItem("microServiceIdDNSPage");
      localStorage.removeItem("ipDNSPage");
      localStorage.removeItem("portDNSPage");
      localStorage.removeItem("apiKeyDNSPage");
      localStorage.removeItem("apiSecretDNSPage");
      localStorage.removeItem("tokenDNSPage");
    },

    async deleteDNS(idDNS) {
      const url = `${config.default.urlBasePandora}/companies/company-dns/${idDNS}`;

      try {
        const response = await axios({
          url,
          method: "delete",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          await this.$alert("Vinculo DNS excluído!", "Sucesso", "success");
          this.clearFields();
          await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
        }
      } catch (error) {
        console.log({
          error
        });
        await this.$alert("Erro ao excluir o vinculo DNS!", "Erro", "error");
      }
    },

    async getCompanyDetails(companyCnpj) {
      const url = `${config.default.urlBasePandora}/companies/company-details`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            cnpj: companyCnpj
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.dns = response.data.dns;
        }
      } catch (error) {
        console.log({
          error
        });
        this.getNotification("Aviso", "Nenhum registro encontrado!", "warning");
      }
    },

    verifyFields() {
      if (! this.editVinculoDNS && (this.ip || this.port) ) 
        this.insertingVinculoDNS = true;
      else 
        this.insertingVinculoDNS = false;
    },
  },


  beforeMount() {
    this.token = localStorage.getItem("token");
    this.getMicroService();
    this.readCompany();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.verifyFields();
  },
};