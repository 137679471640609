<template>
  <div class="home">
    <b-card
      style="max-width: 100%"
      class="mb-2"
      header-tag="header"
      z-index="10"
    >
      <template #header>
        <h5 class="mb-0">Home</h5>
      </template>

      <img src="../../public/Home.png" alt="home" style="width: 35%">

      <b-card-text>
        <br>
        <h4>
          Sistema gerencial, responsável pelo start dos aplicativos Accesys!
        </h4>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "HomeComp",
};
</script>
<style lang="scss" src="../assets/scss/home.scss" scoped />