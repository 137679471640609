import Vue from "vue";
import VueRouter from "vue-router";
import HomeComp from "../views/HomeComp.vue";
import Login from "../views/LoginComp.vue";
import AboutComp from "../views/AboutComp.vue";
import DefaultContainer from "../views/DefaultContainer";
import CompaniesFdv from "../views/Fdv/CompaniesComp";
import DevicesFdv from "../views/Fdv/DevicesComp";
import UsersAdc from "../views/DataCollector/UsersComp";
import StartPlataformas from "../views/StartPlataformas/CompaniesPlataformas"
import CompanyManager from "../views/StartPlataformas/CompanyManager"
import UsersPandora from "../views/UsersPandora/UsersPandora"
import UsersManager from "../views/UsersPandora/UsersManager"
import CaptureImage from "../views/ImageCamera/CaptureImage"
import OpenTheDoor from "../views/OpenTheDoor/OpenTheDoor"
import AccessType from "../views/AccessType/AccessType"
import AccessTypeManager from "../views/AccessType/AccessTypeManager"
import MicroService from "../views/MicroService/MicroService"
import MicroServiceManager from "../views/MicroService/MicroServiceManager"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Início",
    component: DefaultContainer,
    children: [
      {
        path: "home",
        name: "HomeComp",
        component: HomeComp,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "about",
        name: "AboutComp",
        component: AboutComp,
      },
      {
        path: "companies-fdv",
        name: "CompaniesFdv",
        component: CompaniesFdv,
      },
      {
        path: "devices-fdv",
        name: "DevicesFdv",
        component: DevicesFdv,
      },
      {
        path: "users-adc",
        name: "UsersAdc",
        component: UsersAdc,
      },
      {
        path: "start-plataformas",
        name: "StartPlataformas",
        component: StartPlataformas,
      },  
      {
        path: "company-manager",
        name: "CompanyManager",
        component: CompanyManager,
      },  
      {
        path: "users-pca",
        name: "UsersPandora",
        component: UsersPandora,
      }, 
      {
        path: "users-manager",
        name: "UsersManager",
        component: UsersManager,
      }, 
      {
        path: "capture-image",
        name: "CaptureImage",
        component: CaptureImage,
      }, 
      {
        path: "open-the-door",
        name: "OpenTheDoor",
        component: OpenTheDoor,
      }, 
      {
        path: "access-type",
        name: "AccessType",
        component: AccessType,
      }, 
      {
        path: "access-type-manager",
        name: "AccessTypeManager",
        component: AccessTypeManager,
      }, 
      {
        path: "micro-service",
        name: "MicroService",
        component: MicroService,
      }, 
      {
        path: "micro-service-manager",
        name: "MicroServiceManager",
        component: MicroServiceManager,
      }, 
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (sessionStorage.getItem("logado") != undefined) {
      next();
    } else {
      router.push({ name: "Login" });
    }
  } else {
    next();
  }
});
export default router;
