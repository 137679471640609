import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "OpenTheDoor",
  data() {
    return {
      opened: false,
      trying: 0,
      maxTry: 100,
      spinner: false
    };
  },

  methods: {
    async testStatusDoor() {
      for (this.trying; this.trying < this.maxTry; this.trying++) {
        this.spinner = true;
        await this.getStatus();
      }
      this.spinner = false;
    },

    redirect(ref) {
      this.$router.push(ref);
    },

    async getStatus() {
      const url = `${config.default.urlBaseOpenTheDoor}`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            "deviceId": "100139a003"
          }
        });

        if (response.status == 200) {
          if (response.data.switchStatus == "off") {
            this.opened = false;
          } else {
            this.opened = true;
          }
        }
      } catch (error) {

        await this.$alert(`Erro ao testar o status do sensor da porta. Erro: ${error}`, "Erro", "error");
        console.log({
          error
        })
      }
    }
  },

  beforeMount() {
    this.trying = 0;
    this.testStatusDoor();
  },
}