import axios from "axios";
import * as config from "@/config.json";
import {
  parseNumber,
  valueOrDefault
} from "../../../functions/functions";

export default {
  name: "OrderStatusPage",

  data() {
    return {
      description: "",
      index: "",
      selectedOperation: null,
      optionsOperation: [{
          value: 0,
          text: "0 - Pedido Trasmitido",
        },
        {
          value: 1,
          text: "1 - Pedido Recebido",
        },
        {
          value: 2,
          text: "2 - Pedido em Separação",
        },
        {
          value: 3,
          text: "3 - Aguardando Retirada",
        },
        {
          value: 4,
          text: "4 - Aguardando Entrega",
        },
        {
          value: 5,
          text: "5 - Saiu para Entrega",
        },
        {
          value: 6,
          text: "6 - Documento Fiscal Emitido",
        },
        {
          value: 7,
          text: "7 - Entregue",
        },
        {
          value: 8,
          text: "8 - Pedido Cancelado",
        },
        {
          value: 9,
          text: "9 - Aguardando Pagamento",
        },
        {
          value: 10,
          text: "10 - Pedido Aprovado",
        },
        {
          value: 11,
          text: "11 - Pedido Recusado",
        },
        {
          value: 12,
          text: "12 - Pedido Estornado",
        },
      ],
      orderStatus: [],
      fields: [{
          key: "id",
          label: "ID"
        },
        {
          key: "description",
          label: "Descrição"
        },
        {
          key: "index",
          label: "Índice"
        },
        {
          key: "operation",
          label: "Operação"
        },
        {
          key: "isInitialStatus",
          label: "Status Inicial"
        },
      ],
      isInitialStatus: false,
      currentPage: 1,
      perPage: 10,
      idOrderStatus: "",
      editOrderStatusCompany: false,
      insertingOrderStatusCompany: false,
    };
  },

  methods: {
    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.orderStatus = this.$route.params.orderStatus;
    },

    fillLocalStorage() {
      localStorage.setItem("descriptionOrderStatus", valueOrDefault(this.description,""));
      localStorage.setItem("indexOrderStatus", parseNumber(this.index,0));
      localStorage.setItem("operationOrderStatus", parseNumber(this.selectedOperation, null));
      localStorage.setItem("isInitialStatusOrderStatus", this.isInitialStatus);
    },

    clearLocalStorage() {
      localStorage.removeItem("descriptionOrderStatus");
      localStorage.removeItem("indexOrderStatus");
      localStorage.removeItem("operationOrderStatus");
      localStorage.removeItem("isInitialStatusOrderStatus");
    },

    clearFields() {
      this.idOrderStatus = "";
      this.description = "";
      this.index = "";
      this.selectedOperation = null;
      this.isInitialStatus = false;

      this.editOrderStatusCompany = false;
    },

    async buildOrderStatus(cnpj, description, index, operation, isInitialStatus) {
      const orderStatus = {
        cnpj: cnpj,
        description: valueOrDefault(description, ""),
        index: parseNumber(index),
        operation: parseNumber(operation),
        isInitialStatus: isInitialStatus,
      };

      return orderStatus;
    },

    editOrderStatus(orderStatus) {
      this.idOrderStatus = orderStatus.id;
      this.description = orderStatus.description;
      this.index = orderStatus.index;
      this.selectedOperation = orderStatus.selectedOperation;
      this.isInitialStatus = orderStatus.isInitialStatus;

      this.editOrderStatusCompany = true;
    },

    async gravarOrderStatus() {
      const url = `${config.default.urlBasePandora}/companies/order-status`;

      try {
        const response = await axios({
          url,
          method: "post",
          data: await this.buildOrderStatus(localStorage.getItem("cnpjPrincipalPage"), this.description, this.index, this.selectedOperation, this.isInitialStatus),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          this.editOrderStatusCompany = false;
          await this.$alert("Status do pedido cadastrado!", "Sucesso", "success");
          this.clearFields();
          await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
        }
      } catch (error) {
        console.log(error);
        await this.$alert("Erro ao excluir o Status do pedido!", "Erro", "error");
      }

    },

      async deleteOrderStatus(idOrderStatus) {
        const url = `${config.default.urlBasePandora}/companies/order-status/${idOrderStatus}`;

        try {
          const response = await axios({
            url,
            method: "delete",
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            await this.$alert("Status do Pedido excluído!", "Sucesso", "success");
            this.clearFields();
            await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
          }
        } catch (error) {
          console.log({
            error
          });
          await this.$alert("Erro ao excluir o Status do Pedido!", "Erro", "error");
        }
      },

    async getCompanyDetails(companyCnpj) {
      const url = `${config.default.urlBasePandora}/companies/company-details`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            cnpj: companyCnpj
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.orderStatus = response.data.orderStatus;
        }
      } catch (error) {
        console.log({
          error
        });
        this.getNotification("Aviso", "Nenhum registro encontrado!", "warning");
      }
    },

    verifyFields() {
      if (! this.editOrderStatusCompany && (this.description || this.index || this.selectedOperation) ) 
        this.insertingOrderStatusCompany = true;
      else 
        this.insertingOrderStatusCompany = false;
    },
  },

  beforeMount() {
    this.token = localStorage.getItem("token");
    this.readCompany();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.verifyFields();
  },
};