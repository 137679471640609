<template>
  <div class="DefaultContainer">
    <NavBar />
    <SideBar/>

    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import SideBar from "../components/SideBar.vue";

export default {
  name: "DefaultContainer",
  components: { NavBar, SideBar },
};
</script>
