export function valueOrDefault(field, defaultValue) {
  return field ? field : defaultValue;
}

export function parseNumber(number) {
  if (number) {
    return parseInt(valueOrDefault(number, 0));
  }
  return 0;
}

export function parseBoolean(field) {
  if (field == "true") {
    return true;
  } else {
    return false;
  }
}

export function parseFieldBoolean(field) {
  if (field == 'true') {
    return true;
  } else {
    return false;
  }
}

export function parseBooleanToInteger(field) {
  if (field == "true") {
    return 1;
  } else {
    return 0;
  }
}