import { render, staticRenderFns } from "../../assets/templates/StartPlataformas/OrderStatusPage.html?vue&type=template&id=790a045c&scoped=true&"
import script from "../../assets/scripts/StartPlataformas/OrderStatusPage.js?vue&type=script&lang=js&"
export * from "../../assets/scripts/StartPlataformas/OrderStatusPage.js?vue&type=script&lang=js&"
import style0 from "../../assets/scss/orderStatusPage.scss?vue&type=style&index=0&id=790a045c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790a045c",
  null
  
)

export default component.exports