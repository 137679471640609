<template>
  <div>
    <div class="animated fadeIn">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      />
    </div>

    <b-col>
      <transition name="slide">
        <b-card
          tag="article"
          style="max-width: 100%"
          class="mb-2"
          header-tag="header"
        >
          <template #header>
            <h5 class="mb-0">Usuários Cadastrados</h5>
          </template>

          <b-row>
            <b-col xl="4" lg="6" md="12" sm="12">
              <b-input-group prepend="Opções de Filtro">
                <b-form-select v-model="selectedOption" :options="options" />
              </b-input-group>
            </b-col>

            <div class="form-check form-check-inline">
              <input
                id="todos"
                v-model="active"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="todos"
                @change="getUsers(page)"
              />
              <label class="form-check-label" for="inlineRadio1">Todos</label>
            </div>

            <div class="form-check form-check-inline">
              <input
                id="ativo"
                v-model="active"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="ativo"
                @change="getUsers(page)"
              />
              <label class="form-check-label" for="inlineRadio1">Ativos</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="inativo"
                v-model="active"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="inativo"
                @change="getUsers(page)"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Inativos</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                id="pendente"
                v-model="active"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="pendente"
                @change="getUsers(page)"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Licença Pendente</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                id="ok"
                v-model="active"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="ok"
                @change="getUsers(page)"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Licença Ativa</label
              >
            </div>
          </b-row>

          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12">
              <b-form-group>
                <b-input-group size="md" prepend="Filtro">
                  <b-form-input
                    id="filterInput"
                    v-model="user"
                    type="search"
                    placeholder="Digite para pesquisar"
                    @keyup.enter="getUsers(page)"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!user" @click="cleanSearch()">
                      Limpar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="Primeiro"
              prev-text="Anterior"
              next-text="Próximo"
              last-text="Último"
            >
            </b-pagination>
          </b-row>
          <b-row />

          <b-table
            hover
            striped
            bordered
            responsive="sm"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            head-variant="light"
            @row-clicked="abrirModal"
          >
            <template v-slot:cell(inactive)="data">
              <b-badge v-if="data.item.inactive == 0" variant="success">
                {{ data.item.inactive == 0 ? "Sim" : "Não" }}
              </b-badge>
              <b-badge v-else variant="danger">
                {{ data.item.inactive == 1 ? "Não" : "Sim" }}
              </b-badge>
            </template>
          </b-table>
          <b-button block variant="secondary" @click="redirect('home')">
            <i class="fa fa-arrow-circle-left"></i>
            Voltar
          </b-button>
        </b-card>
      </transition>
    </b-col>
    <b-modal
      ref="modalLicenseUser"
      hide-footer
      title="Dados Dispositivos"
      size="xl"
    >
      <b-table
        ref="modalLicenseUser"
        hover
        striped
        bordered
        responsive="sm"
        :items="licenses"
        :fields="fieldsLicense"
        head-variant="light"
        @row-clicked="abrirModalUpdateLicense"
      >
        <template v-slot:cell(expiration)="data">
          <b-badge
            v-if="data.item.expiration == 'Invalid date'"
            variant="transparent"
          >
            {{
              data.item.expiration == "Invalid date" ? "" : data.item.expiration
            }}
          </b-badge>
          <b-badge v-else variant="transparent">
            {{
              data.item.expiration != "Invalid date" ? data.item.expiration : ""
            }}
          </b-badge>
        </template>
        <template v-slot:cell(status)="data">
          <b-badge
            v-if="data.item.status == 'Licença Válida'"
            variant="success"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge v-else variant="danger">
            {{ data.item.status }}
          </b-badge>
        </template>
      </b-table>
      <div class="modal-footer">
        <b-button @click="fecharModal('modalLicenseUser')"> Fechar </b-button>
      </div>
    </b-modal>

    <b-modal
      ref="modalUpdateLicense"
      hide-footer
      title="Atualização de Licença"
      size="md"
    >
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <h6 v-if="dataLicense == 'Invalid date'" style="color: red">
            Sem licença válida
          </h6>
          <h6
            v-else-if="statusLicense == 'Licença Inválida'"
            style="color: red"
          >
            Licença expirou em: {{ dataLicense }}
          </h6>

          <h6 v-else style="color: green">
            Licença válida até: {{ dataLicense }}
          </h6>
          <b-input-group prepend="Opções de Licença">
            <b-form-select
              v-model="selectedLicense"
              :options="optionsLicense"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <div>
        <br />

        <h5 for="example-datepicker">Vencimento</h5>

        <b-form-datepicker
          id="example-datepicker"
          v-model="dateExpiration"
          placeholder="Selecione a data"
          class="mb-2"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="pt"
        />
      </div>

      <div class="modal-footer">
        <b-button @click="fecharModal('modalUpdateLicense')"> Fechar </b-button>
        <b-button
          v-b-modal.modal-xl
          variant="primary"
          @click="putLicenses(deviceId)"
        >
          Atualizar Licença
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import * as config from "@/config.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
  name: "UsersComp",
  components: { Loading },
  props: ["source", "page"],
  data() {
    return {
      items: [],
      licenses: [],
      fields: [
        { key: "inactive", label: "Ativo" },
        { key: "id", label: "ID" },
        { key: "name", label: "Nome" },
        { key: "doc", label: "Documentação" },
        { key: "phone", label: "Telefone" },
      ],
      fieldsLicense: [
        { key: "deviceId", label: "ID Dispositivo" },
        { key: "version", label: "Versão" },
        { key: "createdAt", label: "Criação" },
        { key: "updatedAt", label: "Atualização" },
        { key: "expiration", label: "Validade" },
        { key: "status", label: "Status" },
      ],

      optionsLicense: [
        {
          value: null,
          text: "lite",
        },
      ],

      options: [
        {
          value: null,
          text: "Nome",
        },
        {
          value: 1,
          text: "Documentação",
        },
      ],
      active: "",
      selectedOption: null,
      selectedLicense: null,
      user: "",
      id: "",
      deviceId: "",
      currentPage: 1,
      perPage: 12,
      isLoading: false,
      showLicense: 0,
      ptBR: ptBR,
      dateExpiration: null,
      dataLicense: null,
      statusLicense: null,
    };
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getUsers(1);
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {
    redirect(ref) {
      if (ref == "home") {
        this.$router.push("home");
      }
    },
    fecharModal(refModal) {
      if (refModal == "modalLicenseUser") {
        this.$refs.modalLicenseUser.hide();
      }
      if (refModal == "modalUpdateLicense") {
        this.$refs.modalUpdateLicense.hide();
      }
    },

    abrirModalUpdateLicense(l) {
      this.deviceId = l.deviceId;
      this.dataLicense = l.expiration;
      this.statusLicense = l.status;
      this.dateExpiration = l.expiration;

      this.$refs.modalUpdateLicense.show();
    },

    async abrirModal(i) {
      this.id = i.id;

      if (this.id != "") {
        await this.getLicenses(this.id);
        if (this.showLicense == 1) {
          this.$refs.modalLicenseUser.show();
        }
      }
    },

    navigate(page) {
      this.getUsers(page);
    },

    cleanSearch() {
      this.user = "";
      this.getUsers(this.page);
    },

    buildParams(option, active, pagina) {
      let params = new Object();

      if (option == null) {
        params.name = this.user;
      }

      if (option == 1) {
        params.doc = this.user;
      }

      if (active == "ativo") {
        params.inactive = 0;
      }

      if (active == "inativo") {
        params.inactive = 1;
      }

      if (active == "pendente") {
        params.pending = 1;
      }
      if (active == "ok") {
        params.pending = 0;
      }

      params.pageSize = this.perPage;
      params.page = pagina;

      return params;
    },

    async getUsers(pagina) {
      const url = `${config.default.urlBaseADC}/user`;
      const params = this.buildParams(this.selectedOption, this.active, pagina);

      return axios({
        url: url,
        method: "get",
        params: params,
        headers: { authorization: `${config.default.authorization}` },
      })
        .then((response) => {
          this.items = response.data.items;
        })
        .catch((error) =>
          console.error({
            errorMessage: `[GetUsers] ${error}`,
          })
        );
    },

    async getLicenses(userId) {
      const url = `${config.default.urlBaseADC}/license/user/${userId}`;
      return axios({
        url: url,
        method: "get",
        headers: { authorization: `${config.default.authorization}` },
      })
        .then((response) => {
          this.licenses = response.data;

          if (response.data.length > 0) {
            this.showLicense = 1;
          } else {
            this.showLicense = 0;
          }
        })
        .catch((error) =>
          console.error({
            errorMessage: `[getLicenses] ${error}`,
          })
        );
    },

    async putLicenses(deviceId) {
      const url = `${config.default.urlBaseADC}/license/device/${deviceId}`;
      const dateExpiredAt = moment(this.dateExpiration).format("YYYY-MM-DD");
      const dateOldLicense = moment(this.dataLicense, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      if (this.selectedLicense == null) {
        this.selectedLicense = 0;
      }

      if (
        (dateOldLicense != "Invalid date" && dateExpiredAt <= dateOldLicense) ||
        dateExpiredAt <= moment(new Date()).format("YYYY-MM-DD") ||
        dateExpiredAt == "Invalid date"
      ) {
        await this.$alert("Data de expiração inválida!", "Erro", "error");
      } else {
        try {
          const response = await axios({
            url: url,
            method: "put",
            headers: { authorization: `${config.default.authorization}` },
            data: {
              version: this.optionsLicense[this.selectedLicense].text,
              expiration: dateExpiredAt,
            },
          });

          if (response.status == 200) {
            await this.$alert("Licença Atualizada!", "Sucesso", "success");

            this.fecharModal("modalUpdateLicense");
            this.fecharModal("modalLicenseUser");

            this.getLicenses(this.id);
            if (this.showLicense == 1) {
              this.$refs.modalLicenseUser.show();
            }

            this.selectedLicense = null;
          }
        } catch (error) {
          console.error({
            errorMessage: `[putLicenses] ${error}`,
          });
          await this.$alert("Falha ao atualizar a licença!", "Erro", "error");
        }
      }
    },
  },
};
</script>
<style lang="scss" src="../../assets/scss/usersADC.scss" scoped />