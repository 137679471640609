import { render, staticRenderFns } from "../../assets/templates/CaptureImage/CaptureImage.html?vue&type=template&id=2e93dc16&scoped=true&"
import script from "../../assets/scripts/CaptureImage/CaptureImage.js?vue&type=script&lang=js&"
export * from "../../assets/scripts/CaptureImage/CaptureImage.js?vue&type=script&lang=js&"
import style0 from "../../assets/scss/captureImage.scss?vue&type=style&index=0&id=2e93dc16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e93dc16",
  null
  
)

export default component.exports