import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "UsersPandora",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      token: "",
      itemsUsers: [],
      users: "",
      currentPage: 1,
      perPage: 10,
      password: "",
      newPassword: "",
      newPasswordConfirmed: "",
      currentName: "",
      currentLogin: "",
      currentEmail: "",
      currentPassword: "",
      state: null,
      name: "",
      login: "",
      email: "",
      paramsUser: {},

      fields: [{
          key: "name",
          label: "Nome"
        },
        {
          key: "login",
          label: "Usuário"
        },
        {
          key: "email",
          label: "E-Mail"
        },
        {
          key: "password",
          label: "Senha"
        },

      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Nome",
        },
        {
          value: 1,
          text: "Login",
        },
      ],
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getUsers(1);
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.users.length;
    },

    passwordState() {
      return this.password.length > 0 ? true : false
    },
    newPasswordState() {
      return this.newPassword.length > 0 ? true : false
    },
    newPasswordConfirmedState() {
      return this.newPasswordConfirmed.length > 0 ? true : false
    },

  },

  async updated() {
    this.state = await this.verifyState()
  },

  methods: {
    verifyState() {
      if (
        this.password != "" &&
        this.newPassword != "" &&
        this.newPasswordConfirmed != ""
      )
        return true
      else
        return false
    },

    openModal(user) {
      this.currentName = user.item.name;
      this.currentLogin = user.item.login;
      this.currentEmail = user.item.email;
      this.currentPassword = user.item.password;

      this.password = "";
      this.newPassword = "";
      this.newPasswordConfirmed = "";

      this.$refs.updatePassword.show();
    },

    async updatePassword(typedPassword, newPassword, newPasswordConfirmed) {
      this.isLoading = true;

      if (newPassword != newPasswordConfirmed) {
        this.isLoading = false;
        await this.$alert("As senhas digitadas não conferem!", "Error", "error");
      } else {
        const url = `${config.default.urlBasePandora}/admin/user`;
        const builtUser = await this.buildUser(this.currentName, this.currentLogin, this.currentEmail, typedPassword, newPassword);

        try {
          const response = await axios({
            url,
            method: "post",
            data: builtUser,
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            this.isLoading = false;
            await this.$alert("Senha atualizada!", "Sucesso", "success");
            this.closeModal('updatePassword');
            this.redirect("users-pca");
          }
        } catch (error) {
          if (error.response.status == 403) {
            this.isLoading = false;
            await this.$alert(
              "Sua sessão expirou. Faça o login novamente!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else {
            this.isLoading = false;
            await this.$alert(`Falha ao atualizar a senha! Erro: ${error}`, "Error", "error");
          }
          console.log({
            error
          })
        }
      }
    },

    async buildUser(name, login, email, password, newPassword) {
      return {
        name,
        login,
        email,
        password,
        newPassword
      }
    },

    closeModal(refModal) {
      if (refModal == "updatePassword") {
        this.$refs.updatePassword.hide();
      }
    },

    redirect(ref) {
      this.$router.push(ref);
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    showPassword(id) {
      let password = document.getElementById(id);

      if (password.type == "password") {
        password.type = "text";
      } else {
        password.type = "password";
      }

      this.focus("password");
    },


    buildParamsUsers(option) {
      let params = new Object();

      if (option == null) {
        params.name = this.users;
      }

      if (option == 1) {
        params.login = this.users;
      }

      params.pageSize = this.perPage;

      return params;
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-right",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },

    buildUserParams(name, login, email) {
      this.paramsUser = {
        name: name,
        login: login,
        email: email
      }
    },

    async getUsers() {
      this.isLoading = true;
      const url = `${config.default.urlBasePandora}/admin/user/search`;
      const params = this.buildParamsUsers(this.selectedOption);

      try {
        const response = await axios({
          url,
          method: "get",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          this.itemsUsers = response.data.users;
        }
      } catch (error) {
        this.isLoading = false;
        console.log({
          error
        })

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            "Nenhum registro encontrado!",
            "warning"
          );
        }
      }
    }
  },
};