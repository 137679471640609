import {
  valueOrDefault,
} from "../../../functions/functions";

export default {
  name: "PrincipalPage",
  data() {
    return {
      cnpjLocal: "",
      stateRegistrationLocal: "",
      corporateNameLocal: "",
      nameLocal: "",
      mainPhoneLocal: "",
      secondaryPhoneLocal: "",
      emailLocal: "",
      latitudeLocal: "",
      longitudeLocal: "",
      addressLocal: "",
      numberLocal: "",
      neighborhoodLocal: "",
      zipCodeLocal: "",
      cityLocal: "",
      stateLocal: "",
      state: null
    };
  },

  beforeMount() {
    this.readCompany();
    this.state = this.verifyState();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.state = this.verifyState();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.state = this.verifyState();

    if (this.state) {
      this.$emit("continue-crm", true);
      localStorage.removeItem("stateCRM");
      localStorage.setItem("stateCRM", "true");
    } else {
      this.$emit("continue-crm", true);
      localStorage.removeItem("stateCRM");
      localStorage.setItem("stateCRM", "false");
    }
  },

  computed: {
    cnpjState() {
      return this.cnpjLocal ? true : false
    },
    stateRegistrationState() {
      return this.stateRegistrationLocal ? true : false
    },
    corporateNameState() {
      return this.corporateNameLocal ? true : false
    },
    nameState() {
      return this.nameLocal ? true : false
    },
  },

  methods: {
    verifyState() {
      if (
        this.cnpjLocal &&
        this.stateRegistrationLocal &&
        this.corporateNameLocal &&
        this.nameLocal
      ) {
        this.$emit("continue-crm", true);
        localStorage.removeItem("stateCRM");
        localStorage.setItem("stateCRM", "true");
        return true
      }
      this.$emit("continue-crm", false);
      localStorage.removeItem("stateCRM");
      localStorage.setItem("stateCRM", "false");
      return false
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    clearLocalStorage() {
      localStorage.removeItem("cnpjPrincipalPage");
      localStorage.removeItem("corporateNamePrincipalPage");
      localStorage.removeItem("namePrincipalPage");
      localStorage.removeItem("stateRegistrationFDVPage");
      localStorage.removeItem("mainPrincipalPage");
      localStorage.removeItem("secondaryPrincipalPage");
      localStorage.removeItem("emailPrincipalPage");
      localStorage.removeItem("latitudePrincipalPage");
      localStorage.removeItem("longitudePrincipalPage");
      localStorage.removeItem("addressPrincipalPage");
      localStorage.removeItem("numberPrincipalPage");
      localStorage.removeItem("neighborhoodPrincipalPage");
      localStorage.removeItem("zipCodePrincipalPage");
      localStorage.removeItem("cityPrincipalPage");
      localStorage.removeItem("statePrincipalPage");
    },

    fillLocalStorage() {
      localStorage.setItem("cnpjPrincipalPage", valueOrDefault(this.cnpjLocal, ""));
      localStorage.setItem(
        "corporateNamePrincipalPage",
        valueOrDefault(this.corporateNameLocal, "")
      );
      localStorage.setItem("namePrincipalPage", valueOrDefault(this.nameLocal, ""));
      localStorage.setItem(
        "stateRegistrationFDVPage",
        valueOrDefault(this.stateRegistrationLocal, "")
      );
      localStorage.setItem("mainPrincipalPage", valueOrDefault(this.mainPhoneLocal, ""));
      localStorage.setItem("secondaryPrincipalPage", valueOrDefault(this.secondaryPhoneLocal, ""));
      localStorage.setItem("emailPrincipalPage", valueOrDefault(this.emailLocal, ""));
      localStorage.setItem("latitudePrincipalPage", valueOrDefault(this.latitudeLocal, ""));
      localStorage.setItem("longitudePrincipalPage", valueOrDefault(this.longitudeLocal,""));
      localStorage.setItem("addressPrincipalPage", valueOrDefault(this.addressLocal,""));
      localStorage.setItem("numberPrincipalPage", valueOrDefault(this.numberLocal,""));
      localStorage.setItem("neighborhoodPrincipalPage", valueOrDefault(this.neighborhoodLocal,""));
      localStorage.setItem("zipCodePrincipalPage", valueOrDefault(this.zipCodeLocal,""));
      localStorage.setItem("cityPrincipalPage", valueOrDefault(this.cityLocal,""));
      localStorage.setItem("statePrincipalPage", valueOrDefault(this.stateLocal,""));
    },

    readCompany() {
      this.cnpjLocal = this.$route.params.cnpj;
      this.corporateNameLocal = this.$route.params.corporateName;
      this.nameLocal = this.$route.params.name;
      this.stateRegistrationLocal = this.$route.params.stateRegistration;
      this.mainPhoneLocal = this.$route.params.mainPhone;
      this.secondaryPhoneLocal = this.$route.params.secondaryPhone;
      this.emailLocal = this.$route.params.email;
      this.latitudeLocal = this.$route.params.latitude;
      this.longitudeLocal = this.$route.params.longitude;
      this.addressLocal = this.$route.params.address;
      this.numberLocal = this.$route.params.number;
      this.neighborhoodLocal = this.$route.params.neighborhood;
      this.zipCodeLocal = this.$route.params.zipCode;
      this.cityLocal = this.$route.params.city;
      this.stateLocal = this.$route.params.state;
    },
  },
};