<template>
  <div>
    <b-sidebar
      id="sidebar-menu"
      aria-labelledby="sidebar-menu-title"
      no-header
      shadow
      bg-variant="primary"
      text-variant="white"
      backdrop
      backdrop-variant="dark"
    >
      <template #default="{ hide }">
        <div class="p-3">
          <div class="d-flex align-items-center flex-column">
            <h4 id="sidebar-menu">Accesys Manager</h4>
            <div class="menu"></div>
          </div>

          <nav class="mb-3">
            <b-nav vertical>
              <b-nav-item to="/home" @click="hide">
                <b-icon icon="house"></b-icon>
                Início
              </b-nav-item>

              <b-nav-item-dropdown
                id="Gerencial"
                toggle-class="text-white"
                text="Gerencial"
                right
              >
                <b-nav-item to="/users-pca" @click="hide">
                  <b-icon icon="person-square"></b-icon>
                  Usuários
                </b-nav-item>

                <b-nav-item to="/access-type" @click="hide">
                  <b-icon icon="key"></b-icon>
                  Tipo de Acesso
                </b-nav-item>

                <b-nav-item to="/micro-service" @click="hide">
                  <b-icon icon="cpu"></b-icon>
                  Tipo de Micro Serviço
                </b-nav-item>

                <!-- <b-nav-item to="/capture-image" @click="hide">
                  <b-icon icon="camera-fill"></b-icon>
                  Capturar Imagem
                </b-nav-item>

                <b-nav-item to="/open-the-door" @click="hide">
                  <b-icon icon="door-open-fill"></b-icon>
                  Abertura de Porta
                </b-nav-item> -->
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Plataformas"
                toggle-class="text-white"
                text="Plataformas"
                right
              >
                <b-nav-item to="/start-plataformas" @click="hide">
                  <b-icon icon="ui-checks"></b-icon>
                  Start Plataformas
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="FDV"
                toggle-class="text-white"
                text="FDV Mobile"
                right
              >
                <b-nav-item
                  style="background-color: #303030"
                  to="/companies-fdv"
                  @click="hide"
                >
                  <b-icon icon="shop"></b-icon>
                  Empresas
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="COLLECTOR"
                toggle-class="text-white"
                text="Data Collector"
                right
              >
                <b-nav-item
                  style="background-color: #303030"
                  to="/users-adc"
                  @click="hide"
                >
                  <b-icon icon="people"></b-icon>
                  Usuários
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item to="/about" @click="hide">
                <b-icon icon="info-circle"></b-icon>
                Sobre
              </b-nav-item>

              <b-nav-item to="/login" @click="hide">
                <b-icon icon="door-open"></b-icon>
                Sair
              </b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "SideBar",
};
</script>

<style lang="scss" src="../assets/scss/sidebar.scss" scoped />
