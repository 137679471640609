import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "CaptureImage",
  components: {
    Loading
  },
  data() {
    return {
      captured: false,
      base64: "",
      people: "",
      object: "",
      confidence: 99,
      isLoading: false,
      selectedChannel: 1,
      optionsChannel: [{
          value: 1,
          text: "1 - Escada Área de Lazer"
        },
        {
          value: 2,
          text: "2 - Área de Lazer"
        },
        {
          value: 3,
          text: "3 - Terreno Fundos"
        },
        {
          value: 4,
          text: "4 - Garagem Cima"
        },
        {
          value: 5,
          text: "5 - Garagem Baixo"
        },
        {
          value: 6,
          text: "6 - Frente Cima"
        },
        {
          value: 7,
          text: "7 - Garagem"
        },
        {
          value: 8,
          text: "8 - Garagem Lazer"
        },
        {
          value: 9,
          text: "9 - Sala de Reunião"
        },
        {
          value: 10,
          text: "10 - Recepção Frente"
        },
        {
          value: 11,
          text: "11 - Recepção Escada"
        },
        {
          value: 12,
          text: "12 - Frente Baixo"
        },
        {
          value: 13,
          text: "13 - Lateral Sesi Baixo"
        },
        {
          value: 14,
          text: "14 - Cozinha"
        },
        {
          value: 15,
          text: "15 - Administrativo"
        },
        {
          value: 16,
          text: "16 - Hardware"
        },
        {
          value: 17,
          text: "17 - Hardware Expedição"
        },
        {
          value: 18,
          text: "18 - Corredor Lateral"
        },
        {
          value: 19,
          text: "19 - Lateral Sesi Cima"
        },
        {
          value: 20,
          text: "20 - Terreno Sesi"
        },
        {
          value: 21,
          text: "21 - Lavanderia"
        },
        {
          value: 22,
          text: "22 - Diretoria"
        },
        {
          value: 23,
          text: "23 - Recepção Escada Superior"
        },
        {
          value: 24,
          text: "24 - Desenvolvimento"
        },
        {
          value: 25,
          text: "25 - Suporte B"
        },
        {
          value: 26,
          text: "26 - Suporte A"
        },
        {
          value: 27,
          text: "27 - CPD"
        },
        {
          value: 28,
          text: "28 - Sem Câmera"
        },
        {
          value: 29,
          text: "29 - Sem Câmera"
        },
        {
          value: 30,
          text: "30 - Sem Câmera"
        },
        {
          value: 31,
          text: "31 - Sem Câmera"
        },
        {
          value: 32,
          text: "32 - Sem Câmera"
        },
      ]
    };
  },

  methods: {
    redirect(ref) {
      this.$router.push(ref);
    },

    async postImage() {
      this.isLoading = true;
      const url = `${config.default.urlBaseOmnibox}`;

      try {
        const response = await axios({
          url,
          method: "post",
          data: {
            "channel": this.selectedChannel,
            "confidence": this.confidence
          }
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.captured = true;
          this.base64 = response.data.base64;
          this.people = response.data.count
        }
      } catch (error) {
        this.isLoading = false;

        await this.$alert(`Erro ao capturar imagem. Erro: ${error}`, "Erro", "error");
        console.log({
          error
        })
      }
    }
  },
}