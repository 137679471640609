<template>
  <div class="main">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
    <div class="login">
      <div class="content-login">
        <header>
          <img
            class="imgLogo"
            alt="logo"
            src="@/assets/logoB.png"
            height="10"
          />
        </header>
        <div class="form">
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Usuário</label>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="fa fa-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  class="form-control"
                  id="usuario"
                  placeholder="Insira o usuário"
                  v-model="usuario"
                  required
                  @keypress.enter="focus('senha')"
                />
              </b-input-group>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Senha</label>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="fa fa-key"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  class="form-control"
                  id="senha"
                  placeholder="Insira a senha"
                  v-model="senha"
                  required
                />
              </b-input-group>
              <input class="check" type="checkbox" @click="showPassword()" />
              Exibir
            </div>
            <button
              :disabled="senha == ''"
              type="submit"
              class="btn"
              @click.prevent="login"
            >
              Entrar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
export default {
  name: "LoginComp",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      usuario: "",
      senha: "",
    };
  },
  methods: {
    showPassword() {
      let senha = document.getElementById("senha");

      if (senha.type == "password") {
        senha.type = "text";
      } else {
        senha.type = "password";
      }

      this.focus("senha");
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async login() {
      const data = {
        login: this.usuario,
        password: this.senha,
      };

      const url = `${config.default.urlBasePandora}/admin/user/login`;

      try {
        const response = await axios({
          url,
          method: "post",
          data,
        });

        if (response.status == 200) {
          localStorage.setItem("token", response.data.token);

          sessionStorage.setItem("logado", true);
          localStorage.setItem("usuarioLogado", this.usuario);
          this.$router.push("home");
        }
      } catch (error) {
        console.log({ error });
        await this.$alert("Usuário ou senha incorreto!", "Erro", "error");
      }
    },

    clearlocalStorage() {
      localStorage.removeItem("usuarioLogado");
    },
  },

  beforeMount() {},
};
</script>
<style lang="scss" src="../assets/scss/login.scss" scoped />
