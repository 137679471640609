<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
    <b-col>
      <transition name="slide">
        <b-card
          style="max-width: 100%"
          class="mb-2"
          header-tag="header"
          z-index="10"
        >
          <template #header>
            <h5 class="mb-0">Empresas FDV Mobile</h5>
          </template>

          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12">
              <b-input-group prepend="Opções de Filtro">
                <b-form-select
                  id="optionsInput"
                  v-model="selectedOption"
                  :options="options"
                  autofocus
                  @change="focus('filterInput')"
                />
              </b-input-group>
            </b-col>
            <b-col xl="3" lg="6" md="12" sm="12">
              <div class="form-check form-check-inline">
                <input
                  id="todos"
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  v-model="hasExpiredDevices"
                  value="todos"
                  ac
                  @click="focus('filterInput')"
                  @change="getCompanies()"
                />
                <label class="form-check-label" for="inlineRadio1">Todos</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  id="expirados"
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  v-model="hasExpiredDevices"
                  value="expirados"
                  @click="focus('filterInput')"
                  @change="getCompanies()"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Somente Dispositivos Expirados</label
                >
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12">
              <b-form-group>
                <b-input-group size="md" prepend="Filtro">
                  <b-form-input
                    id="filterInput"
                    v-model="company"
                    type="search"
                    placeholder="Digite para pesquisar"
                    @keyup.enter="getCompanies()"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!company" @click="cleanSearch()">
                      Limpar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <div class="overflow-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="Primeiro"
                prev-text="Anterior"
                next-text="Próximo"
                last-text="Último"
              >
              </b-pagination>
            </div>
          </b-row>
          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-table
                hover
                striped
                bordered
                responsive="sm"
                :items="itemsCompanies"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                head-variant="light"
                @row-clicked="openDevices"
              >
              </b-table>
            </b-col>
          </b-row>
          <b-button block variant="secondary" @click="redirect('home')">
            <i class="fa fa-arrow-circle-left"></i>
            Voltar
          </b-button>
        </b-card>
      </transition>
    </b-col>
  </div>
</template> 
<script>
import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "CompaniesComp",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      token: "",
      company: "",
      itemsCompanies: [],
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: "cnpj", label: "CNPJ" },
        { key: "corporateName", label: "Razão Social" },
        { key: "name", label: "Nome Fantasia" },
        { key: "email", label: "E-Mail" },
        { key: "mainPhone", label: "Telefone Principal" },
        { key: "secondaryPhone", label: "Telefone Secundário" },
        { key: "address", label: "Endereço" },
        { key: "number", label: "Nº" },
        { key: "neighborhood", label: "Bairro" },
        { key: "zipCode", label: "CEP" },
        { key: "city", label: "Cidade" },
        { key: "state", label: "Estado" },
      ],
      selectedOption: null,
      options: [
                {
          value: null,
          text: "Selecione a Opção"
        },
        {
          value: 1,
          text: "Razão Social",
        },
        {
          value: 1,
          text: "Nome Fantasia",
        },
        {
          value: 2,
          text: "CNPJ",
        },
      ],
      hasExpiredDevices: false,
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getCompanies(1);
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.itemsCompanies.length;
    },
  },

  methods: {
    redirect(ref) {
      if (ref == "home") {
        this.$router.push("home");
      }
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    cleanSearch() {
      this.company = "";
      this.getCompanies(this.page);
    },

    buildParamsCompanies(hasExpiredDevices, option) {
      let params = new Object();

      if (hasExpiredDevices == "expirados") {
        params.hasExpiredDevices = true;
      }

      if (option == 1) {
        params.corporateName = this.company;
      }

      if (option == 2) {
        params.name = this.company;
      }
      if (option == 3) {
        params.cnpj = this.company;
      }

      params.pageSize = this.perPage;

      return params;
    },

    openDevices(u) {
      localStorage.removeItem("cnpjCompany");
      localStorage.setItem("cnpjCompany", u.cnpj);

      localStorage.removeItem("nameCompany");
      localStorage.setItem("nameCompany", u.name);
      this.$router.push("devices-fdv");
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-right",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },

    async getCompanies() {
      const url = `${config.default.urlBasePandora}/companies/search`;
      const params = this.buildParamsCompanies(
        this.hasExpiredDevices,
        this.selectedOption
      );

      try {
        const response = await axios({
          url,
          method: "get",
          params: params,
          headers: { authorization: `Bearer ${this.token}` },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.itemsCompanies = response.data.companies;
        }
      } catch (error) {
        console.log({ error });
        if (error.response.status == 403) {
          this.isLoading = false;
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            "Nenhum registro encontrado!",
            "warning"
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" src="../../assets/scss/companiesFDV.scss" scoped />