import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "AccessType",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      itemsAccessType: [],
      id: null,
      description: null,
      currentPage: 1,
      perPage: 12,
      token: null,
      fields: [{
          key: "id",
          label: "Id"
        },
        {
          key: "type",
          label: "Descrição"
        },
      ],
      paramsAccessType: {}
    };
  },

  methods: {
    redirect(ref) {
      this.$router.push(ref);
    },

    async builtParamsAccessType() {
      this.paramsAccessType = {
        id: this.id,
        description: this.description
      }
    },

    async editAccessType(accessType) {
      this.id = accessType.Id;
      this.description = accessType.type;

      await this.builtParamsAccessType();

      this.$router.push({
        name: "AccessTypeManager",
        params: this.paramsAccessType
      });
    },

    async getAccessType() {
      this.isLoading = true;
      const url = `${config.default.urlBasePandora}/admin/access-type/search`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            "description": this.description
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });
        
        if (response.status == 200) {
          this.isLoading = false;
          this.itemsAccessType = response.data.accessType;
        }
      } catch (error) {
        this.isLoading = false;

        if (error.response.status == 403) {
          this.isLoading = false;
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            "Nenhum registro encontrado!",
            "warning"
          );
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getAccessType();
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.itemsAccessType.length;
    },
  },
}