import axios from "axios";
import * as config from "@/config.json";
import {
  parseNumber,
  valueOrDefault
} from "../../../functions/functions";

export default {
  name: "FlowPage",

  data() {
    return {
      description: "",
      command: "",
      number: "",
      interval: "",
      ipDns: "",
      portDns: "",
      commandDisable: false,
      isLoading: false,
      flow: [],
      fields: [{
          key: "id",
          label: "Id"
        },
        {
          key: "description",
          label: "Descrição"
        },
        {
          key: "command",
          label: "Comando"
        },
        {
          key: "retries.number",
          label: "Número de Tentativas"
        },
        {
          key: "retries.interval",
          label: "Intervalo das Tentativas"
        },
      ],
      currentPage: 1,
      perPage: 10,
      editFlowCompany: false,
      insertingFlowCompany: false,
      idFlow: "",
    };
  },

  methods: {
    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.flow = this.$route.params.flow;
    },

    fillLocalStorage() {
      localStorage.setItem("descriptionFlowPage", valueOrDefault(this.description,""));
      localStorage.setItem("commandFlowPage", valueOrDefault(this.command,""));
      localStorage.setItem("numberRetriesFlowPage", parseNumber(this.number,0));
      localStorage.setItem("intervalRetriesFlowPage", parseNumber(this.interval,0));
    },

    clearLocalStorage() {
      localStorage.removeItem("descriptionFlowPage");
      localStorage.removeItem("commandFlowPage");
      localStorage.removeItem("numberRetriesFlowPage");
      localStorage.removeItem("intervalRetriesFlowPage");
    },

    editFlow(flow) {
      this.idFlow = flow.id;
      this.description = flow.description;
      this.command = flow.command;
      this.number = flow.retries.number;
      this.interval = flow.retries.interval;
      this.commandDisable = true;

      this.editFlowCompany = true;
    },

    clearFields() {
      this.idFlow = "";
      this.description = "";
      this.command = "";
      this.number = "";
      this.interval = "";
      this.commandDisable = false;

      this.editFlowCompany = false;
    },

    async buildFlowObject(
      cnpj,
      description,
      command,
      number,
      interval
    ) {

      if (cnpj && description && command && number && interval) {
        const flows = {
          companyCnpj: valueOrDefault(cnpj, ""),
          description: valueOrDefault(description, ""),
          command: valueOrDefault(command, ""),
          retries: {
            number: parseNumber(number),
            interval: parseNumber(interval),
          },

        };

        return flows;
      }
      return null;
    },

    async gravarFlow() {
      const url = `${config.default.urlBasePandora}/companies/flows`;

      try {
        const response = await axios({
          url,
          method: "post",
          data: await this.buildFlowObject(localStorage.getItem("cnpjPrincipalPage"), this.description, this.command, this.number, this.interval),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (response.status === 200) {
          this.editFlowCompany = false;
          await this.$alert("Flow cadastrado!", "Sucesso", "success");
          await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
          this.clearFields();
        }
      } catch (error) {
        console.log(error);
        await this.$alert("Erro ao cadastrar o Flow!", "Erro", "error");
      }
    },

    async getCompanyDetails(companyCnpj) {
      const url = `${config.default.urlBasePandora}/companies/company-details`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            cnpj: companyCnpj
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.flow = response.data.flow;
        }
      } catch (error) {
        console.log({
          error
        });
        this.getNotification("Aviso", "Nenhum registro encontrado!", "warning");
      }
    },

    async deleteFlow(idFlow) {
      const url = `${config.default.urlBasePandora}/companies/flows/${idFlow}`;

      try {
        const response = await axios({
          url,
          method: "delete",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          await this.$alert("Flow excluído!", "Sucesso", "success");
          this.clearFields();
          await this.getCompanyDetails(localStorage.getItem("cnpjPrincipalPage"));
        }
      } catch (error) {
        console.log({
          error
        });
        await this.$alert("Erro ao excluir o Flow!", "Erro", "error");
      }
    },

    verifyFields() {
      if (! this.editFlowCompany && (this.description || this.command || this.number || this.interval) ) 
        this.insertingFlowCompany = true;
      else 
        this.insertingFlowCompany = false;
    },
  },



  beforeMount() {
    this.token = localStorage.getItem("token");
    this.readCompany();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
    this.verifyFields();
  },
};