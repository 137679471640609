<template>
  <div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
    <b-col>
      <transition name="slide">
        <b-card style="max-width: 100%" class="mb-2" header-tag="header">
          <template #header>
            <h5 class="mb-0">Dispositívos FDV Mobile</h5>
            <h6>Empresa: {{ nameCompany }}</h6>
          </template>

          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12">
              <b-input-group prepend="Opções de Filtro">
                <b-form-select
                  id="optionsInput"
                  v-model="selectedOption"
                  :options="options"
                  @change="focus('filterInput')"
                />
              </b-input-group>
            </b-col>
            <b-col xl="3" lg="6" md="12" sm="12">
              <div class="form-check form-check-inline">
                <input
                  id="todos"
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  v-model="onlyExpiredDevices"
                  value="todos"
                  @click="focus('filterInput')"
                  @change="getDevices()"
                />
                <label class="form-check-label" for="inlineRadio1">Todos</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  id="expirados"
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  v-model="onlyExpiredDevices"
                  value="expirados"
                  @click="focus('filterInput')"
                  @change="getDevices()"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Somente Dispositivos Expirados</label
                >
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12">
              <b-form-group>
                <b-input-group size="md" prepend="Filtro">
                  <b-form-input
                    id="filterInput"
                    v-model="device"
                    type="search"
                    placeholder="Digite para pesquisar"
                    autofocus
                    @keyup.enter="getDevices()"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!device" @click="cleanSearch()">
                      Limpar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <div class="overflow-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                first-text="Primeiro"
                prev-text="Anterior"
                next-text="Próximo"
                last-text="Último"
              >
              </b-pagination>
            </div>
          </b-row>
          <b-row>
            <b-col xl="12" lg="12" md="12" sm="12">
              <b-table
                hover
                striped
                bordered
                responsive="sm"
                :items="itemsDevices"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                head-variant="light"
                @row-clicked="abrirModalUpdateLicense"
              >
                <template v-slot:cell(expired)="data">
                  <b-badge v-if="data.item.expired == false" variant="success">
                    Licença Válida
                  </b-badge>
                  <b-badge v-else variant="danger">
                    Licença Expirada
                  </b-badge>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-button block variant="secondary" @click="redirect('CompaniesFDV')">
            <i class="fa fa-arrow-circle-left"></i>
            Voltar
          </b-button>
        </b-card>
      </transition>
    </b-col>

    <b-modal
      ref="modalUpdateLicense"
      hide-footer
      title="Atualização de Licença"
      size="md"
    >
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12">
          <h6 v-if="expiryAt == 'Invalid date'" style="color: red">
            Sem licença válida
          </h6>
          <h6 v-else-if="statusLicense == true" style="color: red">
            Licença expirou em: {{ expiryAt }}
          </h6>

          <h6 v-else style="color: green">
            Licença válida até: {{ expiryAt }}
          </h6>
        </b-col>
      </b-row>
      <div>
        <br />

        <h5 for="example-datepicker">Vencimento</h5>

        <b-form-datepicker
          id="example-datepicker"
          v-model="newDateExpiry"
          placeholder="Selecione a data"
          class="mb-2"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          locale="pt"
        />
      </div>

      <div class="modal-footer">
        <b-button
          @click="fecharModal('modalUpdateLicense')"
          variant="secondary"
        >
          Fechar
        </b-button>
        <b-button
          v-b-modal.modal-xl
          variant="primary"
          @click="putLicenses(deviceId, newDateExpiry)"
        >
          Atualizar Licença
        </b-button>
      </div>
    </b-modal>
  </div>
</template> 
<script>
import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";
import moment from "moment";
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
  name: "DevicesComp",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      token: "",
      device: "",
      itemsDevices: [],
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nome" },
        { key: "createdAt", label: "Instalação" },
        { key: "expiredAt", label: "Expiração" },
        { key: "expired", label: "Status" },
        { key: "updatedAt", label: "Atualização" },
        { key: "userLastModify", label: "Usuário Última Modificação" },
      ],
      selectedOption: null,
      options: [
        {
          value: null,
          text: "Nome",
        },
      ],
      onlyExpiredDevices: false,
      cnpjCompany: "",
      nameCompany: "",
      deviceId: "",
      statusLicense: "",
      expiryAt: "",
      newDateExpiry: "",
      ptBR: ptBR,
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.cnpjCompany = localStorage.getItem("cnpjCompany");
    this.nameCompany = localStorage.getItem("nameCompany");

    await this.getDevices();
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.itemsDevices.length;
    },
  },

  methods: {
    fecharModal(refModal) {
      if (refModal == "modalUpdateLicense") {
        this.$refs.modalUpdateLicense.hide();
      }
    },

    abrirModalUpdateLicense(l) {
      this.deviceId = l.id;
      this.statusLicense = l.expired;
      this.expiryAt = l.expiredAt;

      this.$refs.modalUpdateLicense.show();
    },

    redirect(ref) {
      if (ref == "CompaniesFDV") {
        this.$router.push("companies-fdv");
      }
    },
    focus(id) {
      document.getElementById(id).focus();
    },

    cleanSearch() {
      this.companie = "";
      this.getDevices(this.page);
    },

    buildParamsDevices(onlyExpiredDevices, cnpjCompany, option) {
      let params = new Object();

      if (onlyExpiredDevices == "expirados") {
        params.onlyExpiredDevices = true;
      }

      if (cnpjCompany != "") {
        params.cnpj = cnpjCompany;
      }

      if (option == null) {
        params.deviceName = this.device;
      }
      
      return params;
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-right",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },

    async putLicenses(deviceId, newDateExpiry) {
      const url = `${config.default.urlBasePandora}/devices/license/update`;

      try {
        const response = await axios({
          url: url,
          method: "patch",
          headers: { authorization: `Bearer ${this.token}` },
          data: {
            deviceId: deviceId,
            expiryAt: moment(newDateExpiry).format("YYYY-MM-DD"),
          },
        });

        if (response.status == 200) {
          this.fecharModal("modalUpdateLicense");
          await this.$alert("Licença atualizada!", "Sucesso", "success");

          await this.getDevices();
        }
      } catch (error) {
        console.log({ error });
        this.$alert("Falha ao atualizar a licença!", "Erro", "error");
      }
    },

    async getDevices() {
      const url = `${config.default.urlBasePandora}/devices/search`;
      const params = this.buildParamsDevices(
        this.onlyExpiredDevices,
        this.cnpjCompany,
        this.selectedOption
      );
      try {
        const response = await axios({
          url,
          method: "get",
          params: params,
          headers: { authorization: `Bearer ${this.token}` },
        });

        console.log({response})

        if (response.status == 200) {
          this.isLoading = false;
          this.itemsDevices = response.data.devices;
        }
      } catch (error) {
        console.log({ error });

        if (error.response.status == 403) {
          this.isLoading = false;
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            "Nenhum registro encontrado!",
            "warning"
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" src="../../assets/scss/devicesFDV.scss" scoped />