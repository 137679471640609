<template>
  <div>
    <b-navbar toggleable="lg" variant="primary">
      <b-button
        class="itensNavbar"
        v-b-toggle.sidebar-menu
        variant="dark"
        size="sm"
      >
        <b-icon icon="list"></b-icon>
      </b-button>
      <img class="imgLogo" alt="logo" src="@/assets/logo.png" />
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "NavBar",
};
</script>

<style scopped>
.imgLogo {
  height: 100%;
}
.navbar {
  height: 70px;
}
.itensNavbar {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
