import {
  valueOrDefault,
  parseNumber
} from "../../../functions/functions";

export default {
  name: "CommercePage",
  props: {
    stateCommerce : Boolean,
  },

  data() {
    return {
      sellerId: 0,
      apiAddress: "",
      consumerKey: "",
      consumerSecret: "",
      code: "",
      accessToken: "",
      state: null,
    };
  },
  beforeMount() {
    this.readCompany();
  },

  mounted() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  updated() {
    this.clearLocalStorage();
    this.fillLocalStorage();
  },

  computed: {
    stateSellerId() {
      return this.sellerId ? true : false
    },
    
  },

  methods: {
    focus(id) {
      document.getElementById(id).focus();
    },

    readCompany() {
      this.accessToken = this.$route.params.accessToken;
      this.apiAddress = this.$route.params.apiAddress;
      this.code = this.$route.params.code;
      this.consumerKey = this.$route.params.consumerKey;
      this.consumerSecret = this.$route.params.consumerSecret;
      this.sellerId = this.$route.params.sellerId;

    },

    fillLocalStorage() {
      localStorage.setItem("sellerIdCommercePage", parseNumber(this.sellerId, 0));
      localStorage.setItem("apiAddressCommercePage", valueOrDefault(this.apiAddress, ""));
      localStorage.setItem("consumerKeyCommercePage", valueOrDefault(this.consumerKey,""));
      localStorage.setItem("consumerSecretCommercePage", valueOrDefault(this.consumerSecret,""));
      localStorage.setItem("codeCommercePage", valueOrDefault(this.code,""));
      localStorage.setItem("accessTokenCommercePage", valueOrDefault(this.accessToken,""));
    },

    clearLocalStorage() {
      localStorage.removeItem("sellerIdCommercePage");
      localStorage.removeItem("apiAddressCommercePage");
      localStorage.removeItem("consumerKeyCommercePage");
      localStorage.removeItem("consumerSecretCommercePage");
      localStorage.removeItem("codeCommercePage");
      localStorage.removeItem("accessTokenCommercePage");
    },
  },
};