import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "AccessTypeManager",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      description: null,
      id: null,
      state: false,
      url: null,
      method: null
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.readAccessType();
    this.state = this.verifyState();
    this.isLoading = false;
  },

  async updated() {
    this.state = await this.verifyState()
  },

  computed: {
    descriptionState() {
      return this.description ? true : false
    },
  },

  methods: {
    verifyState() {
      if (this.description == null || this.description == '')
        return false
      else
        return true
    },

    redirect(ref) {
      this.$router.push(ref);
    },

    readAccessType() {
      this.id = this.$route.params.id;
      this.description = this.$route.params.description;
    },

    async CreateOrUpdate() {
      this.isLoading = true;

      if (!this.id) {
        this.url = `${config.default.urlBasePandora}/admin/access-type`;
        this.method = "post";
      } else {
        this.url = `${config.default.urlBasePandora}/admin/access-type/${this.id}`;
        this.method = "put";
      }

      try {
        const response = await axios({
          url: this.url,
          method: this.method,
          data: {
            "description": this.description
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          await this.$alert("Tipo de acesso cadastrado!", "Sucesso", "success");
          this.redirect("access-type");
        }
      } catch (error) {
        if (error.response.status == 403) {
          this.isLoading = false;
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else {
          this.isLoading = false;
          await this.$alert("Falha ao cadastrar o tipo de acesso!", "Error", "error");
        }
        console.log({
          error
        })
      }

    },
  },
};