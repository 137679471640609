import Loading from "vue-loading-overlay";
import axios from "axios";
import * as config from "@/config.json";
import {
  parseNumber,
  parseBoolean,
  valueOrDefault,
} from "../../../functions/functions";

import PrincipalPage from "../../../views/StartPlataformas/PrincipalPage";
import CRMPage from "../../../views/StartPlataformas/CRMPage";
import FDVPage from "../../../views/StartPlataformas//FDVPage";
import CommercePage from "../../../views/StartPlataformas/CommercePage.vue";
import VinculosPage from "../../../views/StartPlataformas/VinculosPage.vue"
import FlowPage from "../../../views/StartPlataformas/FlowPage.vue";
import OrderStatusPage from "../../../views/StartPlataformas/OrderStatusPage.vue";

export default {
  name: "CompanyManager",

  components: {
    Loading,
    PrincipalPage,
    CRMPage,
    FDVPage,
    CommercePage,
    VinculosPage,
    FlowPage,
    OrderStatusPage,
  },
  data() {
    return {
      isLoading: false,
      tabIndex: 0,
      hasCompany: false,
      stateCRM: false,
      stateFDV: false,
      stateCommerce: false,
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.hasCompany = localStorage.getItem("hasCompany");
    this.clearLocalStorage();
    this.isLoading = false;
  },

  updated() {
    this.verifyStatePages();
  },

  methods: {
    verifyStatePages() {
      if (localStorage.getItem("stateCRM") == "true") {
        this.stateCRM = true;
      } else {
        this.stateCRM = false;
      }

      if (localStorage.getItem("stateFDV") == "true") {
        this.stateFDV = true;
      } else {
        this.stateFDV = false;
      }

      if (localStorage.getItem("stateCommerce") == "true") {
        this.stateCommerce = true;
      } else {
        this.stateCommerce = false;
      }
    },

    async FinishStartCompany() {
      if (this.hasCompany == "true") {

        this.isLoading = true;

        const url = `${config.default.urlBasePandora}/companies`;
        const bodyCompany = await this.buildBodyCompany(
          localStorage.getItem("cnpjPrincipalPage"),
          localStorage.getItem("corporateNamePrincipalPage"),
          localStorage.getItem("namePrincipalPage")
        );

        try {
          const response = await axios({
            url,
            method: "post",
            data: bodyCompany,
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            this.isLoading = false;
            this.clearLocalStorage();
            await this.$alert("Empresa cadastrada!", "Sucesso", "success");
            this.redirect("start-plataformas");
          }
        } catch (error) {
          this.isLoading = false;

          console.log({
            error
          });

          if (error.response.status == 403) {
            await this.$alert(
              "Sua sessão expirou. Faça o login novamente!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else if (error.response.status == 401) {
            await this.$alert(
              "Usuário não encontrado na base de dados!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else {
            await this.$alert("Falha ao cadatrar a empresa!", "Error", "error");
          }
        }
      } else {
        this.isLoading = true;
        this.clearLocalStorage();
        this.isLoading = false;
        await this.$alert("Empresa cadastrada!", "Sucesso", "success");
        this.redirect("start-plataformas");
      }
    },

    // localStorage.removeItem("cnpjPrincipalPage"),
    //     localStorage.removeItem("corporateNamePrincipalPage"),
    //     localStorage.removeItem("namePrincipalPage");
    //   localStorage.removeItem("usesCRMPage"),
    //     localStorage.removeItem("monthlyPushsCRMPage"),
    //     localStorage.removeItem("allowedDevicesCRMPage"),
    //     localStorage.removeItem("firebaseProjectCodeCRMPage"),
    //     localStorage.removeItem("firebaseProjectApiCRMPage"),
    //     localStorage.removeItem("showOriginalPricePromotionCRMPage"),
    //     localStorage.removeItem("registrationDiscountTypeCRMPage"),
    //     localStorage.removeItem("registrationDiscountAmountCRMPage"),
    //     localStorage.removeItem("menuTypeCRMPage"),
    //     localStorage.removeItem("useFDVCRMPage"),
    //     localStorage.removeItem("isGasStationCRMPage");
    //   localStorage.removeItem("remoteIdFDVPage");
    //   localStorage.removeItem("minimumPurchaseAmountFDVPage"),
    //     localStorage.removeItem("showUnavailableProductsFDVPage"),
    //     localStorage.removeItem("hasDeliveryGatewayTaxFDVPage"),
    //     localStorage.removeItem("deliveryNotesFDVPage"),
    //     localStorage.removeItem("pickUpOnSiteNotesFDVPage"),
    //     localStorage.removeItem("isThirdPartyDeliveryGatewayPreferredFDVPage"),
    //     localStorage.removeItem("taxFDVPage"),
    //     localStorage.removeItem("usersFranchiseFDVPage"),
    //     localStorage.removeItem("amountPerFranchiseFDVPage"),
    //     localStorage.removeItem("monthlyAllowedRefoundsFDVPage"),
    //     localStorage.removeItem("minimumBillingAmountFDVPage"),
    //     localStorage.removeItem("isRequestConfirmationOrderFDVPage"),
    //     localStorage.removeItem("notesRequiredTextAlertFDVPage"),
    //     localStorage.removeItem("showInterestsFDVPage"),
    //     localStorage.removeItem("sellerIdCommercePage"),
    //     localStorage.removeItem("apiAddressCommercePage"),
    //     localStorage.removeItem("consumerKeyCommercePage"),
    //     localStorage.removeItem("consumerSecretCommercePage"),
    //     localStorage.removeItem("codeCommercePage"),
    //     localStorage.removeItem("accessTokenCommercePage");


    clearLocalStorage() {
      localStorage.removeItem("cnpjPrincipalPage"),
        localStorage.removeItem("corporateNamePrincipalPage"),
        localStorage.removeItem("namePrincipalPage");
      localStorage.removeItem("usesCRMPage"),
        localStorage.removeItem("monthlyPushsCRMPage"),
        localStorage.removeItem("allowedDevicesCRMPage"),
        localStorage.removeItem("firebaseProjectCodeCRMPage"),
        localStorage.removeItem("firebaseProjectApiCRMPage"),
        localStorage.removeItem("showOriginalPricePromotionCRMPage"),
        localStorage.removeItem("registrationDiscountTypeCRMPage"),
        localStorage.removeItem("registrationDiscountAmountCRMPage"),
        localStorage.removeItem("menuTypeCRMPage"),
        localStorage.removeItem("useFDVCRMPage"),
        localStorage.removeItem("isGasStationCRMPage");
      localStorage.removeItem("remoteIdFDVPage");
      localStorage.removeItem("minimumPurchaseAmountFDVPage"),
        localStorage.removeItem("showUnavailableProductsFDVPage"),
        localStorage.removeItem("hasDeliveryGatewayTaxFDVPage"),
        localStorage.removeItem("deliveryNotesFDVPage"),
        localStorage.removeItem("pickUpOnSiteNotesFDVPage"),
        localStorage.removeItem("isThirdPartyDeliveryGatewayPreferredFDVPage"),
        localStorage.removeItem("taxFDVPage"),
        localStorage.removeItem("usersFranchiseFDVPage"),
        localStorage.removeItem("amountPerFranchiseFDVPage"),
        localStorage.removeItem("monthlyAllowedRefoundsFDVPage"),
        localStorage.removeItem("minimumBillingAmountFDVPage"),
        localStorage.removeItem("isRequestConfirmationOrderFDVPage"),
        localStorage.removeItem("notesRequiredTextAlertFDVPage"),
        localStorage.removeItem("showInterestsFDVPage"),
        localStorage.removeItem("sellerIdCommercePage"),
        localStorage.removeItem("apiAddressCommercePage"),
        localStorage.removeItem("consumerKeyCommercePage"),
        localStorage.removeItem("consumerSecretCommercePage"),
        localStorage.removeItem("codeCommercePage"),
        localStorage.removeItem("accessTokenCommercePage");
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async buildBodyCompany(cnpj, corporateName, name) {
      const crm = await this.buildCRMObject(
        localStorage.getItem("usesCRMPage"),
        localStorage.getItem("monthlyPushsCRMPage"),
        localStorage.getItem("allowedDevicesCRMPage"),
        localStorage.getItem("firebaseProjectCodeCRMPage"),
        localStorage.getItem("firebaseProjectApiCRMPage"),
        localStorage.getItem("showOriginalPricePromotionCRMPage"),
        localStorage.getItem("registrationDiscountTypeCRMPage"),
        localStorage.getItem("registrationDiscountAmountCRMPage"),
        localStorage.getItem("menuTypeCRMPage"),
        localStorage.getItem("mainPrincipalPage"),
        localStorage.getItem("secondaryPrincipalPage"),
        localStorage.getItem("emailPrincipalPage"),
        localStorage.getItem("latitudePrincipalPage"),
        localStorage.getItem("longitudePrincipalPage"),
        localStorage.getItem("addressPrincipalPage"),
        localStorage.getItem("numberPrincipalPage"),
        localStorage.getItem("neighborhoodPrincipalPage"),
        localStorage.getItem("zipCodePrincipalPage"),
        localStorage.getItem("cityPrincipalPage"),
        localStorage.getItem("statePrincipalPage"),
        localStorage.getItem("useFDVCRMPage"),
        localStorage.getItem("isGasStationCRMPage")
      );

      const fdv = await this.buildFDVObject(
        localStorage.getItem("stateRegistrationFDVPage"),
        localStorage.getItem("remoteIdFDVPage"),
        localStorage.getItem("minimumPurchaseAmountFDVPage"),
        localStorage.getItem("showUnavailableProductsFDVPage"),
        localStorage.getItem("hasDeliveryGatewayTaxFDVPage"),
        localStorage.getItem("deliveryNotesFDVPage"),
        localStorage.getItem("pickUpOnSiteNotesFDVPage"),
        localStorage.getItem("isThirdPartyDeliveryGatewayPreferredFDVPage"),
        localStorage.getItem("taxFDVPage"),
        localStorage.getItem("usersFranchiseFDVPage"),
        localStorage.getItem("amountPerFranchiseFDVPage"),
        localStorage.getItem("monthlyAllowedRefoundsFDVPage"),
        localStorage.getItem("minimumBillingAmountFDVPage"),
        localStorage.getItem("isRequestConfirmationOrderFDVPage"),
        localStorage.getItem("notesRequiredTextAlertFDVPage"),
        localStorage.getItem("showInterestsFDVPage")
      );

      const commerce = await this.buildCommerceObject(
        localStorage.getItem("sellerIdCommercePage"),
        localStorage.getItem("apiAddressCommercePage"),
        localStorage.getItem("consumerKeyCommercePage"),
        localStorage.getItem("consumerSecretCommercePage"),
        localStorage.getItem("codeCommercePage"),
        localStorage.getItem("accessTokenCommercePage")
      );

      return {
        cnpj,
        corporateName,
        name,
        crm,
        fdv,
        commerce,
      };
    },

    async buildCRMObject(
      usesCRM,
      monthlyPushs,
      allowedDevices,
      firebaseProjectCode,
      firebaseProjectApi,
      showOriginalPricePromotion,
      registrationDiscountType,
      registrationDiscountAmount,
      menuType,
      mainPhone,
      secondaryPhone,
      email,
      latitude,
      longitude,
      address,
      number,
      neighborhood,
      zipCode,
      city,
      state,
      useFDV,
      isGasStation
    ) {
      const crm = new Object();

      crm.usesCrm = parseNumber(usesCRM, 0);
      crm.monthlyPushs = parseNumber(monthlyPushs);
      crm.allowedDevices = parseNumber(allowedDevices);
      crm.firebaseProjectCode = valueOrDefault(firebaseProjectCode, "");
      crm.firebaseProjectApi = valueOrDefault(firebaseProjectApi, "");
      crm.showOriginalPricePromotion = parseBoolean(showOriginalPricePromotion);
      crm.registrationDiscountType = parseNumber(registrationDiscountType);
      crm.registrationDiscountAmount = parseNumber(registrationDiscountAmount);
      crm.menuType = parseNumber(menuType);

      crm.mainPhone = valueOrDefault(mainPhone, "");
      crm.secondaryPhone = valueOrDefault(secondaryPhone, "");
      crm.email = valueOrDefault(email, "");
      crm.latitude = valueOrDefault(latitude, "");
      crm.longitude = valueOrDefault(longitude, "");
      crm.address = valueOrDefault(address, "");
      crm.number = valueOrDefault(number, "");
      crm.neighborhood = valueOrDefault(neighborhood, "");
      crm.zipCode = valueOrDefault(zipCode, "");
      crm.city = valueOrDefault(city, "");
      crm.state = valueOrDefault(state, "");

      crm.useFdv = parseBoolean(useFDV);
      crm.isGasStation = parseBoolean(isGasStation);

      return crm;
    },

    async buildFDVObject(
      stateRegistration,
      remoteId,
      minimumPurchaseAmount,
      showUnavailableProducts,
      hasDeliveryGatewayTax,
      deliveryNotes,
      pickUpOnSiteNotes,
      isThirdPartyDeliveryGatewayPreferred,
      tax,
      usersFranchise,
      amountPerFranchise,
      monthlyAllowedRefounds,
      minimumBillingAmount,
      isRequestConfirmationOrder,
      notesRequiredTextAlert,
      showInterests
    ) {
      const fdv = new Object();

      fdv.stateRegistration = stateRegistration;
      fdv.remoteId = parseNumber(remoteId);
      fdv.minimumPurchaseAmount = parseNumber(minimumPurchaseAmount);
      fdv.showUnavailableProducts = parseBoolean(showUnavailableProducts);
      fdv.hasDeliveryGatewayTax = parseBoolean(hasDeliveryGatewayTax);
      fdv.deliveryNotes = valueOrDefault(deliveryNotes, "");
      fdv.pickUpOnSiteNotes = valueOrDefault(pickUpOnSiteNotes, "");
      fdv.isThirdPartyDeliveryGatewayPreferred = parseBoolean(
        isThirdPartyDeliveryGatewayPreferred
      );
      fdv.tax = parseNumber(tax);
      fdv.usersFranchise = parseNumber(usersFranchise);
      fdv.amountPerFranchise = parseNumber(amountPerFranchise);
      fdv.monthlyAllowedRefounds = parseNumber(monthlyAllowedRefounds);
      fdv.minimumBillingAmount = parseNumber(minimumBillingAmount);
      fdv.isRequestConfirmationOrder = parseBoolean(isRequestConfirmationOrder);
      fdv.notesRequiredTextAlert = valueOrDefault(notesRequiredTextAlert, "");
      fdv.showInterests = parseBoolean(showInterests);

      return fdv;
    },

    async buildCommerceObject(
      sellerId,
      apiAddress,
      consumerKey,
      consumerSecret,
      code,
      accessToken
    ) {
      const commerce = new Object();

      commerce.sellerId = parseNumber(sellerId);
      commerce.apiAddress = valueOrDefault(apiAddress, "");
      commerce.consumerKey = valueOrDefault(consumerKey, "");
      commerce.consumerSecret = valueOrDefault(consumerSecret, "");
      commerce.code = valueOrDefault(code, "");
      commerce.accessToken = valueOrDefault(accessToken, "");

      return commerce;
    },

    redirect(path) {
      this.$router.push(path);
    },

    async createOrUpdateCompany() {
      this.isLoading = true;

      const url = `${config.default.urlBasePandora}/companies`;
      const bodyCompany = await this.buildBodyCompany(
        localStorage.getItem("cnpjPrincipalPage"),
        localStorage.getItem("corporateNamePrincipalPage"),
        localStorage.getItem("namePrincipalPage")
      );

      try {
        const response = await axios({
          url,
          method: "post",
          data: bodyCompany,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          this.hasCompany = true;

          setTimeout(() => {
            this.tabIndex = 4;
          }, 500);
        }
      } catch (error) {
        this.isLoading = false;
        this.hasCompany = false;
        console.log({
          error
        });

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 401) {
          await this.$alert(
            "Usuário não encontrado na base de dados!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else {
          await this.$alert("Falha ao cadatrar a empresa!", "Error", "error");
        }
      }
    },
  },
};