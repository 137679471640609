import Loading from "vue-loading-overlay";
import * as config from "@/config.json";
import axios from "axios";

export default {
  name: "StartPlataformas",
  components: {
    Loading
  },
  data() {
    return {
      crm: {},
      fdv: {},
      commerce: {},
      dns: {},
      flow: {},
      orderStatus: {},
      isLoading: false,
      token: "",
      company: "",
      Companies: [],
      currentPage: 1,
      perPage: 10,
      fields: [{
          key: "cnpj",
          label: "CNPJ"
        },
        {
          key: "stateRegistration",
          label: "I.E"
        },
        {
          key: "corporateName",
          label: "Razão Social"
        },
        {
          key: "name",
          label: "Nome Fantasia"
        },
        {
          key: "email",
          label: "E-Mail"
        },
        {
          key: "mainPhone",
          label: "Telefone Principal"
        },
        {
          key: "secondaryPhone",
          label: "Telefone Secundário"
        },
        {
          key: "address",
          label: "Endereço"
        },
        {
          key: "number",
          label: "Nº"
        },
        {
          key: "neighborhood",
          label: "Bairro"
        },
        {
          key: "zipCode",
          label: "CEP"
        },
        {
          key: "city",
          label: "Cidade"
        },
        {
          key: "state",
          label: "Estado"
        },
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Razão Social",
        },
        {
          value: 1,
          text: "Nome Fantasia",
        },
        {
          value: 2,
          text: "CNPJ",
        },
      ],

      paramsCompanyDetails: {},

      cnpjDetail: "",
      corporateNameDetail: "",
      nameDetail: "",
      stateRegistrationDetail: "",
      mainPhoneDetail: "",
      secondaryPhoneDetail: "",
      addressPhoneDetail: "",
      neighborhoodPhoneDetail: "",
      cityDetail: "",
      stateDetail: "",
      zipCodeDetail: "",
      numberDetail: "",
      emailDetail: "",
      latitudeDetail: "",
      longitudeDetail: "",
    };
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getCompanies(1);
    this.isLoading = false;
  },

  computed: {
    rows() {
      return this.Companies.length;
    },
  },

  methods: {
    redirect(ref) {
      this.$router.push(ref);
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    cleanSearch() {
      this.company = "";
      this.getCompanies(this.page);
    },

    buildParamsCompanies(option) {
      let params = new Object();

      if (option == null) {
        params.corporateName = this.company;
      }

      if (option == 1) {
        params.name = this.company;
      }
      if (option == 2) {
        params.cnpj = this.company;
      }

      params.pageSize = this.perPage;

      return params;
    },

    async getCompanies() {
      const url = `${config.default.urlBasePandora}/companies/search`;
      const params = this.buildParamsCompanies(this.selectedOption);

      try {
        const response = await axios({
          url,
          method: "get",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.Companies = response.data.companies;
        }
      } catch (error) {
        console.log({
          error
        });
        this.isLoading = false;
        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            "Nenhum registro encontrado!",
            "warning"
          );
        }
      }
    },

    async buildParamsDetails() {
      this.paramsCompanyDetails = {
        cnpj: this.cnpjDetail,
        corporateName: this.corporateNameDetail,
        name: this.nameDetail,
        stateRegistration: this.stateRegistrationDetail,
        mainPhone: this.mainPhoneDetail,
        secondaryPhone: this.secondaryPhoneDetail,
        email: this.emailDetail,
        latitude: this.latitudeDetail,
        longitude: this.longitudeDetail,
        address: this.addressDetail,
        number: this.numberDetail,
        neighborhood: this.neighborhoodDetail,
        zipCode: this.zipCodeDetail,
        city: this.cityDetail,
        state: this.stateDetail,

        allowedDevices: this.crm.allowedDevices,
        firebaseProjectId: this.crm.firebaseProjectId,
        firebaseProjectKey: this.crm.firebaseProjectKey,
        isGasStation: this.crm.isGasStation,
        menuType: this.crm.menuType,
        monthlyPushs: this.crm.monthlyPushs,
        registerDiscountType: this.crm.registerDiscountType,
        registerDiscountValue: this.crm.registerDiscountValue,
        showOriginalPromotionPrice: this.crm.showOriginalPromotionPrice,
        usesCRM: this.crm.useCRM,
        usesFDV: this.crm.useFDV,

        amountPerFranchise: this.fdv.amountPerFranchise,
        deliveryNotes: this.fdv.deliveryNotes,
        hasDeliveryGatewayTax: this.fdv.hasDeliveryGatewayTax,
        isRequestConfirmationOrder: this.fdv.isRequestConfirmationOrder,
        isThirdPartyDeliveryGatewayPreferred: this.fdv.isThirdPartyDeliveryGatewayPreferred,
        minimumBillingAmount: this.fdv.minimumBillingAmount,
        minimumPurchaseAmount: this.fdv.minimumPurchaseAmount,
        monthlyAllowedRefounds: this.fdv.monthlyAllowedRefounds,
        notesRequiredTextAlert: this.fdv.notesRequiredTextAlert,
        pickUpOnSiteNotes: this.fdv.pickUpOnSiteNotes,
        remoteId: this.fdv.remoteId,
        showInterests: this.fdv.showInterests,
        showUnavailableProducts: this.fdv.showUnavailableProducts,
        tax: this.fdv.tax,
        usersFranchise: this.fdv.usersFranchise,

        accessToken: this.commerce.accessToken,
        apiAddress: this.commerce.apiAddress,
        code: this.commerce.code,
        consumerKey: this.commerce.consumerKey,
        consumerSecret: this.commerce.consumerSecret,
        sellerId: this.commerce.sellerId,

        dns: this.dns,
        flow: this.flow,
        orderStatus: this.orderStatus,
      };
    },

    async getCompanyDetails(companyCnpj) {
      const url = `${config.default.urlBasePandora}/companies/company-details`;

      try {
        const response = await axios({
          url,
          method: "get",
          params: {
            cnpj: companyCnpj
          },
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.commerce = response.data.commerce;
          this.crm = response.data.crm;
          this.dns = response.data.dns;
          this.fdv = response.data.fdv;
          this.flow = response.data.flow;
          this.orderStatus = response.data.orderStatus;

          await this.buildParamsDetails()
        }
      } catch (error) {
        console.log({
          error
        });
        this.getNotification("Aviso", "Nenhum registro encontrado!", "warning");
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-right",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
      });
    },

    async editCompany(company) {
      this.cnpjDetail = company.cnpj;
      this.corporateNameDetail = company.corporateName;
      this.nameDetail = company.name;
      this.stateRegistrationDetail = company.stateRegistration;
      this.mainPhoneDetail = company.mainPhone;
      this.secondaryPhoneDetail = company.secondaryPhone;
      this.addressDetail = company.address;
      this.neighborhoodDetail = company.neighborhood;
      this.cityDetail = company.city;
      this.stateDetail = company.state;
      this.zipCodeDetail = company.zipCode;
      this.numberDetail = company.number;
      this.emailDetail = company.email;
      this.latitudeDetail = company.latitude;
      this.longitudeDetail = company.longitude;

      await this.getCompanyDetails(company.cnpj);

      localStorage.removeItem("hasCompany");
      localStorage.setItem("hasCompany", true);

      this.$router.push({
        name: "CompanyManager",
        params: this.paramsCompanyDetails,
      });
    },
  },
};